
export const getCsvDownloadUrl = (clientName, serviceName, startDate, endDate) => {

    return process.env.REACT_APP_API_URL + "/services_csv_data?clientName="
        +clientName
        +"&serviceName="+serviceName+"&startDate="+
        startDate+"&endDate="+endDate

    // if (clientName !== "invoid" && clientName !== "payone") {
    //     return "http://127.0.0.1:5000/services_csv_data?clientName="
    //         +clientName
    //         +"invoid&serviceName="+serviceName+"&startDate="+
    //         startDate+"&endDate="+endDate
    // }

    // return "http://127.0.0.1:5000/services_csv_data?clientName="
    //     +clientName
    //     +"&serviceName="+serviceName+"&startDate="+
    //     startDate+"&endDate="+endDate
    
}


export const getTransactionCountUrl = (clientName, serviceName, startDate, endDate) => {
    if (clientName !== "invoid") {
        return process.env.REACT_APP_API_URL + "/services_count?clientName="
            +clientName
            +"invoid&serviceName="+serviceName+"&startDate="+
            startDate+"&endDate="+endDate
    }

    return process.env.REACT_APP_API_URL + "/services_count?clientName="
        +clientName
        +"&serviceName="+serviceName+"&startDate="+
        startDate+"&endDate="+endDate
}

export function getDisplayName(name) {
    if (name === 'emailOtp')
        return 'Email OTP'

    if (name === 'namematch')
        return 'Name Match'

    if (name === 'webocr')
        return 'Link based verification'

    if (name === 'consistency')
        return 'Consistency'

    if (name === 'utility')
        return 'Utility'

    if (name === 'mask')
        return 'Aadhaar Masking'

    if (name === 'otp')
        return 'Mobile OTP'

    if (name === 'governmentCheck')
        return 'Official DB check'

    if (name === 'liveness')
        return 'Face Liveness'

    if (name === 'bulkUpload')
        return 'Bulk Upload'

    if (name === 'liveness')
        return 'Face Liveness'

    if (name === 'bangla')
        return 'Bangladesh NID'

    if (name === 'malaysia')
        return 'Malaysia NID'

    if (name === 'videoKyc')
        return 'Video KYC'

    if (name === 'xml')
        return 'Aadhaar XML'

    if (name === 'selfie')
        return 'Face Match'

    
    if (name === 'cardLiveness')
        return 'Card Liveness'
    
    if (name === 'photocopy')
        return 'Photocopy'
    
    if (name === 'fv')
        return 'FV'
    
    if (name === 'ic')
        return 'IC'
    
    if (name === 'governmentCheck-pan') {
        return 'Official DB Check (PAN)'
    }

    if (name === 'governmentCheck-panSupreme') {
        return 'Official DB Check (PAN Supreme)'
    }

    if (name === 'governmentCheck-dl') {
        return 'Official DB Check (DL)'
    }

    if (name === 'governmentCheck-voter') {
        return 'Official DB Check (Voter)'
    }

    if (name === 'governmentCheck-dlPrime') {
        return 'Official DB Check (DL Prime)'
    }

    if (name === 'governmentCheck-bank') {
        return 'Official DB Check (Bank A/C)'
    }

    if (name === 'governmentCheck-rcLite') {
        return 'Official DB Check (RC Lite)'
    }

    if (name === 'governmentCheck-rcPrime') {
        return 'Official DB Check (RC Prime)'
    }

    if (name === 'governmentCheck-gstLite') {
        return 'Official DB Check (GST Lite)'
    }

    if (name === 'governmentCheck-gstAdvance') {
        return 'Official DB Check (GST Advance)'
    }

    if (name === 'governmentCheck-panPrime') {
        return 'Official DB Check (Pan Prime)'
    }

    if (name === 'governmentCheck-panLite') {
        return 'Official DB Check (Pan Lite)'
    }
    
    return name
}

export function getTooltipText(name) {
    if (name === 'emailOtp')
        return 'Email OTP'

    if (name === 'namematch')
        return 'namematch.invoid.co'

    if (name === 'webocr')
        return 'Link based verification'

    if (name === 'consistency')
        return 'consistency.invoid.co'

    if (name === 'utility')
        return 'Utility'

    if (name === 'mask')
        return 'mask.invoid.co'

    if (name === 'otp')
        return 'Mobile OTP'

    if (name === 'governmentCheck')
        return 'gc.invoid.co'

    if (name === 'bulkUpload')
        return 'Bulk Upload'

    if (name === 'liveness')
        return 'liveness.invoid.co/v2'

    if (name === 'bangla')
        return 'Bangladesh NID'

    if (name === 'malaysia')
        return 'Malaysia NID'

    if (name === 'videoKyc')
        return 'Video KYC'

    if (name === 'xml')
        return 'init-aadhaar.invoid.co'

    if (name === 'selfie')
        return 'facematch.invoid.co'
    
    if (name === 'cardLiveness')
        return 'cardliveness.invoid.co'
    
    if (name === 'photocopy')
        return 'photocopy.invoid.co'
    
    if (name === 'fv')
        return 'verifyface.invoid.co'
    
    if (name === 'ic')
        return 'ic.invoid.co'
    
    return name
}

export function getClientActualName(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}
  
export function convertToIST(epoch, isStartDate) {
    // console.log(epoch)
    var e;
    if (isStartDate) {
       e = epoch - (5.5 * 60 * 60 * 1000)
    } else {
        e = epoch + (18.5 * 60 * 60 * 1000)
    }
    if (isStartDate) {
        console.log("startDate",e)
    } else {
        console.log("endDate",e)
    }
    const date = new Date(e)
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);

    const sec = ("0" + date.getUTCSeconds()).slice(-2);

    return `${year}-${month}-${day} ${date.getUTCHours()}:${date.getUTCMinutes()}:${sec}`

}

export function convertToPoperDate(epoch) {
    const date = new Date(epoch)
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    
    return `${day}-${month}-${year}`

}

export const lastMonthDate = 1;
export const customDate = 2;

export function getLastMonthStartDate() {

    // 2021-02-28 18:30:00"

    var time = new Date();
    time.setDate(1);
    time.setMonth(time.getMonth() - 1);
    time.setHours(0, 0, 0, 0)
    time = time.getTime()


    const date = new Date(time)
   
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const sec = ("0" + date.getUTCSeconds()).slice(-2);

    return `${year}-${month}-${day} ${date.getUTCHours()}:${date.getUTCMinutes()}:${sec}`
}


export function getLastMonthStartDisplayDate() {
    const date = new Date()

    const firstDay = new Date(date.getFullYear(), date.getMonth() -1, 1);
    const year = date.getFullYear();
    const month = ("0" + (firstDay.getMonth() + 1)).slice(-2);
    const day = ("0" + firstDay.getDate()).slice(-2);

    return `${year}-${month}-${day}`
}

export function getLastMonthEndDate() {

    // 2021-02-28 18:30:00&endDate=2021-03-31 18:30:00
    // "2021-03-31 18:30:00"

    var time = new Date();
    time.setDate(0);
    time.setHours(24, 0, 0, 0)
    time = time.getTime()


    const date = new Date(time)
   
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const sec = ("0" + date.getUTCSeconds()).slice(-2);

    return `${year}-${month}-${day} ${date.getUTCHours()}:${date.getUTCMinutes()}:${sec}`
}

export function getLastMonthEndDisplayDate() {

    const date = new Date()

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 0);
    const year = date.getFullYear();
    const month = ("0" + (firstDay.getMonth() + 1)).slice(-2);
    const day = ("0" + firstDay.getDate()).slice(-2);

    return `${year}-${month}-${day}`
}

export function getPrice(individualDocBillingData, commonPrice, isSamePriceChecked) {

    if (individualDocBillingData.extra !== undefined) {
      return individualDocBillingData["price"]
    }
  
    if (isSamePriceChecked || individualDocBillingData === null) {
      return commonPrice
    } else {
      if (individualDocBillingData['price'] !== null) {
        return individualDocBillingData["price"]
      }
      return commonPrice
    }
  }

export function getClientDisplayName(name) {
    if (name !== 'invoid' && name.slice(-6) === 'invoid') {
        return name.slice(0, -6)
    } 
    return name
}  