import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import { getDisplayName } from "./utils";

import logo from "../../assets/logo_dark_img.png";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 35,
    paddingBottom: 20,
    paddingHorizontal: 35,
    border: "none",
  },
  row: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 30,
    paddingBottom: 15,
    fontWeight: 900,
  },
  info: {
    fontSize: 10,
  },
  images: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "40%",
    margin: 10,
    border: "1px solid #000",
  },
  headerImage: {
    height: "35px",
    width: "100px",
    paddingBottom: 15,
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: "20px",
    borderStyle: "solid",
    borderWidth: 0.7,
    borderSpacing: "0px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0.7,
    borderSpacing: "0px",
    padding: 5,
  },
  tableCol10: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0.7,
    borderSpacing: "0px",
    padding: 5,
  },
  tableCol20: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 0.7,
    borderSpacing: "0px",
    padding: 5,
  },
  tableCol30: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 0.7,
    borderSpacing: "0px",
    padding: 5,
  },
  tableCol40: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 0.7,
    borderSpacing: "0px",
    padding: 5,
  },

  tableCol60: {
    width: "60%",
    borderStyle: "solid",
    borderWidth: 0.7,
    borderSpacing: "0px",
    padding: 5,
  },
  header: {
    margin: "auto",
    marginTop: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: "rgb(21,75,204)",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    width: "100%",
    color: "#fff",
    padding: 10,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },

  tableCellLeft: {
    marginTop: 5,
    fontSize: 8,
  },
  button: {
    padding: "7px 10px",
    height: "32px",
    textAlign: "center",
    marginTop: "50px",
    backgroundColor: "rgb(46,87,158)",
    color: "white",
    outline: "none",
    cursor: "pointer",
    textTransform: "none",
    textDecoration: "none",
    borderRadius: "5px"

  },
  pageNumber: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
  },
});

export function GeneratePdf(props) {

  const currentDate = new Date();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>

          <View style={styles.tableRow}>
            <Image style={styles.headerImage} src={logo} />
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeft}>Invoice Date: {currentDate.toDateString()}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeft}>Invoice Number: { props.invoiceNo }</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeft}>{"RECOGSQUARE PRIVATE LIMITED\nC-22, Basement, Shivalik, Malviya Nagar,\nNew Delhi - 110017, India\nTel. : +91- 9891230291\nPAN: AAJCR2269J\nGSTIN: 07AAJCR2269J1Z0\nRCM : NA"}
                  </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeft}>{"Bill To: " + getBillingAddress(props.billingDetails).replace(/\\n/g, '\n')}
                      </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeft}>{"Place of supply: " + getPlaceOfSupply(props.billingDetails) +"\n"+props.additionalNotes}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}>Sr. No</Text>
            </View>
            <View style={styles.tableCol30}>
              <Text style={styles.tableCellLeft}>Description</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}>Unit</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}>Price</Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCellLeft}>Total Amount (INR)</Text>
            </View>
          </View>

          <BillEntry
            billingData={props.billingData}
            price={props.price}
            isSamePriceChecked={props.isSamePriceChecked} />

          <View style={styles.tableRow}>
            <View style={styles.tableCol60}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
            <View style={styles.tableCol40}>
              <NetCost
                billingData={props.billingData}
                price={props.price}
                isSamePriceChecked={props.isSamePriceChecked}
                billingDetails={props.billingDetails}
              />
            </View>
          </View>


          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>CGST(9%)</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol40}>
            <CgstCost
                cost={calculateNetCost(props.billingData, props.price, props.isSamePriceChecked)}
                billingDetails={props.billingDetails}
              />
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>SGST(9%)</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol40}>
            <SgstCost
                cost={calculateNetCost(props.billingData, props.price, props.isSamePriceChecked)}
                billingDetails={props.billingDetails}
              />
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>IGST(18%)</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol40}>
              <IgstCost
                cost={calculateNetCost(props.billingData, props.price, props.isSamePriceChecked)}
                billingDetails={props.billingDetails}
              />
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Round off/Out of pocket</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCell}>0</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total Amount</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol40}>
              <TotalCost
                cost={calculateNetCost(props.billingData, props.price, props.isSamePriceChecked)}
                igstCost={calculateIgstCost(calculateNetCost(props.billingData, props.price, props.isSamePriceChecked), props.billingDetails)}
                cgstCost={calculateCgstCost(calculateNetCost(props.billingData, props.price, props.isSamePriceChecked), props.billingDetails)}
                sgstCost={calculateSgstCost(calculateNetCost(props.billingData, props.price, props.isSamePriceChecked), props.billingDetails)}
              />
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
            <View style={styles.tableCol30}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol60}>
              <Text style={styles.tableCellLeft}>Please transfer the amount to company account</Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
          </View>


          <View style={styles.tableRow}>
            <View style={styles.tableCol60}>
              <Text style={styles.tableCellLeft}>{"BANK NAME : ICICI BANK\nIFSC CODE : ICIC0000155"}</Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol60}>
              <Text style={styles.tableCellLeft}>Branch: Punjabi Bagh, New Delhi</Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol60}>
              <Text style={styles.tableCellLeft}>{"Ac. Name : RECOGSQUARE PRIVATE LIMITED\nAc. Number: 015505007433"}</Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCellLeft}></Text>
            </View>
          </View>



        </View>
      </Page>
    </Document>
  );

};

function NetCost(props) {
  return <Text style={styles.tableCell}>{"Rs " + calculateNetCost(props.billingData, props.price, props.isSamePriceChecked).toLocaleString()}</Text>
}

function BillEntry(props) {
  var idx = 1
  const doc = []
  for (const key in props.billingData) {
    if (key === 'governmentCheck') {
      
      if (props.billingData['governmentCheck']["pan"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-pan')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["pan"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["pan"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["pan"]["count"] * getPrice(props.billingData['governmentCheck']["pan"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["panSupreme"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-panSupreme')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["panSupreme"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["panSupreme"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["panSupreme"]["count"] * getPrice(props.billingData['governmentCheck']["panSupreme"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["panPrime"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-panPrime')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["panPrime"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["panPrime"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["panPrime"]["count"] * getPrice(props.billingData['governmentCheck']["panPrime"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["panLite"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-panLite')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["panLite"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["panLite"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["panLite"]["count"] * getPrice(props.billingData['governmentCheck']["panLite"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }



      if (props.billingData['governmentCheck']["dl"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-dl')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["dl"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["dl"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["dl"]["count"] * getPrice(props.billingData['governmentCheck']["dl"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["dlPrime"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-dlPrime')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["dlPrime"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["dlPrime"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["dlPrime"]["count"] * getPrice(props.billingData['governmentCheck']["dlPrime"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["voter"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-voter')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["voter"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["voter"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["voter"]["count"] * getPrice(props.billingData['governmentCheck']["voter"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["bank"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-bank')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["bank"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["bank"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["bank"]["count"] * getPrice(props.billingData['governmentCheck']["bank"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["rcLite"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-rcLite')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["rcLite"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["rcLite"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["rcLite"]["count"] * getPrice(props.billingData['governmentCheck']["rcLite"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["rcPrime"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-rcPrime')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["rcPrime"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["rcPrime"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["rcPrime"]["count"] * getPrice(props.billingData['governmentCheck']["rcPrime"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["gstLite"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-gstLite')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["gstLite"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["gstLite"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["gstLite"]["count"] * getPrice(props.billingData['governmentCheck']["gstLite"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['governmentCheck']["gstAdvance"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName('governmentCheck-gstAdvance')}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['governmentCheck']["gstAdvance"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['governmentCheck']["gstAdvance"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['governmentCheck']["gstAdvance"]["count"] * getPrice(props.billingData['governmentCheck']["gstAdvance"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }

      

      
    }
    else if (key === 'fv') {
      
      if (props.billingData['fv']["panFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (PAN)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["panFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["panFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["panFv"]["count"]
              * getPrice(props.billingData['fv']["panFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['fv']["dlFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (DL)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["dlFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["dlFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["dlFv"]["count"]
              * getPrice(props.billingData['fv']["dlFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['fv']["aadhaarFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Aadhaar)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["aadhaarFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["aadhaarFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["aadhaarFv"]["count"]
              * getPrice(props.billingData['fv']["aadhaarFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['fv']["voterFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Voter)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["voterFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["voterFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["voterFv"]["count"]
              * getPrice(props.billingData['fv']["voterFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }



      if (props.billingData['fv']["passportFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Passport)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["passportFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["passportFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["passportFv"]["count"]
              * getPrice(props.billingData['fv']["passportFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['fv']["rcFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (RC)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["rcFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["rcFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["rcFv"]["count"]
              * getPrice(props.billingData['fv']["rcFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['fv']["chequeFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Cheque)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["chequeFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["chequeFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["chequeFv"]["count"]
              * getPrice(props.billingData['fv']["chequeFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['fv']["otherFv"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Other)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['fv']["otherFv"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['fv']["otherFv"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['fv']["otherFv"]["count"]
              * getPrice(props.billingData['fv']["otherFv"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }
    
        
         
      
      
      else {

        if (props.billingData[key]["addToBill"]) {
          const d = <View style={styles.tableRow}>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}>{idx}</Text>
            </View>
            <View style={styles.tableCol30}>
              <Text style={styles.tableCellLeft}>{getDisplayName(key)}</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}>{props.billingData[key]["count"]}</Text>
            </View>
            <View style={styles.tableCol10}>
              <Text style={styles.tableCellLeft}>{getPrice(props.billingData[key], props.price, props.isSamePriceChecked)}</Text>
            </View>
            <View style={styles.tableCol40}>
              <Text style={styles.tableCellLeft}>{(props.billingData[key]["count"] * getPrice(props.billingData[key], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
            </View>
          </View>

          doc.push(d)

          idx = idx + 1

        }
      }
    }
    else if (key === 'ic') {
      
      if (props.billingData['ic']["panIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (PAN)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["panIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["panIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["panIc"]["count"]
              * getPrice(props.billingData['ic']["panIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['ic']["dlIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (DL)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["dlIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["dlIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["dlIc"]["count"]
              * getPrice(props.billingData['ic']["dlIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['ic']["aadhaarIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Aadhaar)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["aadhaarIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["aadhaarIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["aadhaarIc"]["count"]
              * getPrice(props.billingData['ic']["aadhaarIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['ic']["voterIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Voter)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["voterIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["voterIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["voterIc"]["count"]
              * getPrice(props.billingData['ic']["voterIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }



      if (props.billingData['ic']["passportIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Passport)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["passportIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["passportIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["passportIc"]["count"]
              * getPrice(props.billingData['ic']["passportIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['ic']["rcIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (RC)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["rcIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["rcIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["rcIc"]["count"]
              * getPrice(props.billingData['ic']["rcIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['ic']["chequeIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Cheque)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["chequeIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["chequeIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["chequeIc"]["count"]
              * getPrice(props.billingData['ic']["chequeIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }


      if (props.billingData['ic']["otherIc"]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]['label'] + " (Other)"}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData['ic']["otherIc"]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData['ic']["otherIc"], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData['ic']["otherIc"]["count"]
              * getPrice(props.billingData['ic']["otherIc"], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        idx = idx + 1
        doc.push(d)
      }
      
    }
    else {
      if (props.billingData[key]["addToBill"]) {
        const d = <View style={styles.tableRow}>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{idx}</Text>
          </View>
          <View style={styles.tableCol30}>
            <Text style={styles.tableCellLeft}>{getDisplayName(key)}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{props.billingData[key]["count"]}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCellLeft}>{getPrice(props.billingData[key], props.price, props.isSamePriceChecked)}</Text>
          </View>
          <View style={styles.tableCol40}>
            <Text style={styles.tableCellLeft}>{(props.billingData[key]["count"] * getPrice(props.billingData[key], props.price, props.isSamePriceChecked)).toLocaleString()}</Text>
          </View>
        </View>

        doc.push(d)

        idx = idx + 1

      }
    
    }
  }
  if (doc.length > 0) {
    return doc
  }

  return null

}


function calculateNetCost(billingData, price, isSamePriceChecked) {
  var cost = 0

  for (var key in billingData) {
    if (billingData[key]["addToBill"]) {
      const count = billingData[key]["count"]
      cost = cost + (count * getPrice(billingData[key], price, isSamePriceChecked))
    }

    if (key === "governmentCheck") {
      
      if (billingData[key]["pan"]["addToBill"]) {
        const count = billingData[key]["pan"]["count"]
        cost = cost + (count * getPrice(billingData[key]["pan"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["panSupreme"]["addToBill"]) {
        const count = billingData[key]["panSupreme"]["count"]
        cost = cost + (count * getPrice(billingData[key]["panSupreme"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["dl"]["addToBill"]) {
        const count = billingData[key]["dl"]["count"]
        cost = cost + (count * getPrice(billingData[key]["dl"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["dlPrime"]["addToBill"]) {
        const count = billingData[key]["dlPrime"]["count"]
        cost = cost + (count * getPrice(billingData[key]["dlPrime"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["voter"]["addToBill"]) {
        const count = billingData[key]["voter"]["count"]
        cost = cost + (count * getPrice(billingData[key]["voter"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["bank"]["addToBill"]) {
        const count = billingData[key]["bank"]["count"]
        cost = cost + (count * getPrice(billingData[key]["bank"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["rcLite"]["addToBill"]) {
        const count = billingData[key]["rcLite"]["count"]
        cost = cost + (count * getPrice(billingData[key]["rcLite"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["rcPrime"]["addToBill"]) {
        const count = billingData[key]["rcPrime"]["count"]
        cost = cost + (count * getPrice(billingData[key]["rcPrime"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["gstLite"]["addToBill"]) {
        const count = billingData[key]["gstLite"]["count"]
        cost = cost + (count * getPrice(billingData[key]["gstLite"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["gstLite"]["addToBill"]) {
        const count = billingData[key]["gstLite"]["count"]
        cost = cost + (count * getPrice(billingData[key]["gstLite"], price, isSamePriceChecked))
      }

      if (billingData[key]["gstAdvance"]["addToBill"]) {
        const count = billingData[key]["gstAdvance"]["count"]
        cost = cost + (count * getPrice(billingData[key]["gstAdvance"], price, isSamePriceChecked))
      }

      if (billingData[key]["panPrime"]["addToBill"]) {
        const count = billingData[key]["panPrime"]["count"]
        cost = cost + (count * getPrice(billingData[key]["panPrime"], price, isSamePriceChecked))
      }

      if (billingData[key]["panLite"]["addToBill"]) {
        const count = billingData[key]["panLite"]["count"]
        cost = cost + (count * getPrice(billingData[key]["panLite"], price, isSamePriceChecked))
      }
    }

    if (key === "ic") {
      
      if (billingData[key]["panIc"]["addToBill"]) {
        const count = billingData[key]["panIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["panIc"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["dlIc"]["addToBill"]) {
        const count = billingData[key]["dlIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["dlIc"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["aadhaarIc"]["addToBill"]) {
        const count = billingData[key]["aadhaarIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["aadhaarIc"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["passportIc"]["addToBill"]) {
        const count = billingData[key]["passportIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["passportIc"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["voterIc"]["addToBill"]) {
        const count = billingData[key]["voterIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["voterIc"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["chequeIc"]["addToBill"]) {
        const count = billingData[key]["chequeIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["chequeIc"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["rcIc"]["addToBill"]) {
        const count = billingData[key]["rcIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["rcIc"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["otherIc"]["addToBill"]) {
        const count = billingData[key]["otherIc"]["count"]
        cost = cost + (count * getPrice(billingData[key]["otherIc"], price, isSamePriceChecked))
      }
      
    }

    if (key === "fv") {
      
      if (billingData[key]["panFv"]["addToBill"]) {
        const count = billingData[key]["panFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["panFv"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["dlFv"]["addToBill"]) {
        const count = billingData[key]["dlFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["dlFv"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["aadhaarFv"]["addToBill"]) {
        const count = billingData[key]["aadhaarFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["aadhaarFv"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["passportFv"]["addToBill"]) {
        const count = billingData[key]["passportFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["passportFv"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["voterFv"]["addToBill"]) {
        const count = billingData[key]["voterFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["voterFv"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["chequeFv"]["addToBill"]) {
        const count = billingData[key]["chequeFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["chequeFv"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["rcFv"]["addToBill"]) {
        const count = billingData[key]["rcFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["rcFv"], price, isSamePriceChecked))
      }
      
      if (billingData[key]["otherFv"]["addToBill"]) {
        const count = billingData[key]["otherFv"]["count"]
        cost = cost + (count * getPrice(billingData[key]["otherFv"], price, isSamePriceChecked))
      }
      
    }
  }
  return cost
}

function calculateIgstCost(cost, billingDetails) {
  if (billingDetails && billingDetails['addIgst']) {
    return cost * 0.18
  }
  return 0.0
}

function calculateCgstCost(cost, billingDetails) {
  if (billingDetails && billingDetails['addCgst']) {
    return cost * 0.09
  }
  return 0.0
}

function calculateSgstCost(cost, billingDetails) {
  if (billingDetails && billingDetails['addCgst']) {
    return cost * 0.09
  }
  return 0.0
}

function TotalCost(props) {
  return <Text style={styles.tableCell}>{"Rs " + (props.cost + props.igstCost + props.cgstCost + props.sgstCost).toLocaleString()}</Text>
}

function CgstCost(props) {
  return <Text style={styles.tableCell}>{"Rs " + calculateCgstCost(props.cost, props.billingDetails).toLocaleString()}</Text>  
}

function SgstCost(props) {
  return <Text style={styles.tableCell}>{"Rs " + calculateSgstCost(props.cost, props.billingDetails).toLocaleString()}</Text>
}

function IgstCost(props) {
  return <Text style={styles.tableCell}>{"Rs " + calculateIgstCost(props.cost, props.billingDetails).toLocaleString()}</Text>
}

function getPrice(individualDocBillingData, commonPrice, isSamePriceChecked) {

  if (individualDocBillingData.extra !== undefined) {
    return individualDocBillingData["price"]
  }

  if (isSamePriceChecked || individualDocBillingData === null) {
    return commonPrice
  } else {
    if (individualDocBillingData['price'] !== null) {
      return individualDocBillingData["price"]
    }
    return commonPrice
  }
}

function getBillingAddress(billingDetails) {
  if (billingDetails) {
    return billingDetails['address'];
  }

  return ""
}

function getPlaceOfSupply(billingDetails) {
  if (billingDetails) {
    return billingDetails['placeOfSupply']
  }
  return ""
}



export default GeneratePdf;
