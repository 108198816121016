import React, { Component } from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Input,
  CircularProgress,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from "axios";
import pbkdf2 from 'pbkdf2';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


class NewUser extends Component {
  state = {
    bucketName: "",
    userName: "",
    name: "",
    password: "",
    authkey: "",
    loading: false,
    services: {
      fv: false,
      ic: false,
      gc: false,
      masking: false,
      aadhaarXml: false,
      videoKyc: false,
      linkBased: false,
      liveness: false,
      digilocker: false,
      selfieMatch: false,
      emailOtp: false,
      sendEmail: false,
      androidAadhaarXmlSdk: false,
      androidLivenessAndImageHelperSdk: false,
      bankStatement: false,
      upiDetails: false,
      smsOtp: false
    },
    openXmlDialog: false,
    openKycDialog: false,
    openSubmitDialog: false,
    openLinkBasedDialog: false,
    openDigilockerDialog: false,
    openGovtCheckDialog: false,
    color: "",
    image: "",
    linkBasedColor: "",
    linkBasedImage: "",
    digilockerPrimaryColor: "#3066F0",
    digilockerSecondaryColor: "#3066F0",
    digilockerDoctype: {
      "aadhaar": false,
      "pan": false,
      "dl": false
    },
    digilockerAccountType: "dev",
    digilockerWebhook: "",
    digilockerPassword: "",
    updateData: {},
    videoKycDetails: {
      admin: {
        name: "",
        email: "",
        pass: "",
        confirm: "",
        employeeId: Math.floor(Math.random() * (999 - 100 + 1) + 100),
      },
      agent: {
        name: "",
        email: "",
        pass: "",
        confirm: "",
        employeeId: Math.floor(Math.random() * (999 - 100 + 1) + 100),
      },
      checker: {
        name: "",
        email: "",
        pass: "",
        confirm: "",
        employeeId: Math.floor(Math.random() * (999 - 100 + 1) + 100),
      },
    },
    openSelfieMatchDialog: false,
    selfieMatchDetails: {
      limit: 0
    },
    openEmailOtpDialog: false,
    emailOtpDetails: {
      senderEmail: "",
      limit: 0
    },
    openSendEmailDialog: false,
    sendEmailDetails: {
      senderEmail: "",
      limit: 0
    },
    openBankStatementDialog: false,
    bankStatementDetails: {
      limit: 0
    },
    openUpiDetails: false,
    upiDetails: {
      limit: 0
    },
    smsOtpDetails: {
      limit: 0,
      template: []
    },
    openSmsOtpDialog: false
  };

  createBucket = async () => {
    const { adminAuthKey } = this.props;
    await this.setState({ loading: true });
    try {
      const { bucketName } = this.state;

      const payload = {
        name: bucketName,
      };
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/create-bucket`, payload, { headers: { "authkey": adminAuthKey } });
      if (res.data.status === "2000") {
        await this.setState({ authkey: res.data.authKey });
        this.createAuth();
      } else {
        this.setState({ loading: false });
        alert(res.data.message);
      }

    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  createAuth = async () => {
    const { adminAuthKey } = this.props;
    const { authkey, services, videoKycDetails, image, color, linkBasedColor, linkBasedImage,
      digilockerPrimaryColor, digilockerSecondaryColor, digilockerDoctype, digilockerAccountType,
      digilockerWebhook, bucketName, name, emailOtpDetails, selfieMatchDetails, sendEmailDetails, bankStatementDetails, upiDetails } = this.state;
    try {
      let payload = new FormData();
      payload.append("user", "1");
      payload.append("auth", authkey);
      payload.append("services", JSON.stringify(services));
      if (services.videoKyc) {
        let encVideoKycDetails = JSON.parse(JSON.stringify(videoKycDetails));

        let derivedKey = pbkdf2.pbkdf2Sync(encVideoKycDetails.admin.pass, process.env.REACT_APP_SALT, 100, 64, 'sha512');
        let password_hash = derivedKey.toString('base64');
        encVideoKycDetails.admin.pass = password_hash;

        derivedKey = pbkdf2.pbkdf2Sync(encVideoKycDetails.checker.pass, process.env.REACT_APP_SALT, 100, 64, 'sha512');
        password_hash = derivedKey.toString('base64');
        encVideoKycDetails.checker.pass = password_hash;

        derivedKey = pbkdf2.pbkdf2Sync(encVideoKycDetails.agent.pass, process.env.REACT_APP_SALT, 100, 64, 'sha512');
        password_hash = derivedKey.toString('base64');
        encVideoKycDetails.agent.pass = password_hash;

        payload.append("videoKycDetails", JSON.stringify(encVideoKycDetails));
      }
      if (services.aadhaarXml) {
        payload.append("image", image);
        payload.append("color", color);
      }
      if (services.linkBased) {
        payload.append("linkbased_image", linkBasedImage);
        payload.append("linkbased_color", linkBasedColor);
      }

      if (services.digilocker) {

        const digilockerPassword = this.generateDigilockerPassword()
        this.setState({
          digilockerPassword: digilockerPassword
        })

        payload.append("image", image);
        payload.append("digilockerPrimaryColor", digilockerPrimaryColor);
        payload.append("digilockerSecondaryColor", digilockerSecondaryColor);
        payload.append("digilockerDocType", JSON.stringify(digilockerDoctype));
        payload.append("digilockerAccountType", digilockerAccountType);
        payload.append("digilockerWebhook", digilockerWebhook);
        payload.append("digilockerBucketName", bucketName);
        payload.append("digilockerDisplayName", name);
        payload.append("digilockerPassword", digilockerPassword);
      }


      if (services.emailOtp) {
        payload.append("emailOtpLimit", emailOtpDetails.limit)
        payload.append("emailOtpSenderEmail", emailOtpDetails.senderEmail)
      }

      if (services.sendEmail) {
        payload.append("sendEmailLimit", sendEmailDetails.limit)
        payload.append("sendEmailSenderEmail", sendEmailDetails.senderEmail)
      }

      if (services.selfieMatch) {
        payload.append("selfieMatchLimit", selfieMatchDetails.limit)
      }

      if (services.bankStatement) {
        payload.append("bankStatementLimit", bankStatementDetails.limit)
      }

      if (services.upiDetails) {
        payload.append("upiDetailsLimit", upiDetails.limit)
      }

      if (services.gc) {
        payload.append("govtCheck", JSON.stringify(this.state.updateData));
      }

      if (services.smsOtp) {
        payload.append("smsOtp", JSON.stringify(this.state.smsOtpDetails));
      }

      let res = await axios.post(`${process.env.REACT_APP_API_URL}/create-auth`, payload, { headers: { "authkey": adminAuthKey } });
      if (res.data.message) {
        this.setState({ loading: false });
        alert(res.data.message);
      } else {
        this.createUser();
      }
    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  createUser = async () => {

    const { authkey, userName, name, password } = this.state;
    const { adminAuthKey } = this.props;
    try {
      const payload = {
        username: userName,
        first_name: name,
        password: password,
        authkey: authkey,
      };
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/create-user`, payload, { headers: { "authkey": adminAuthKey } });

      if (res.data.message) {
        alert(res.data.message);
      }
      this.setState({ openSubmitDialog: true });
    } catch (e) {
      alert(e);
    }
    this.setState({ loading: false });
  };

  handleGovtCheckSubmit = () => {
    let govtCheck = ["dl", "pan", "pan-lite", "aadhaar", "passport", "voter", "gst-advance", "gst-lite", "rc",
      "rc-lite", "rc-prime", "bank-account", "offline-aadhaar", "pan-supreme", "dl-prime",
      "udyog-aadhaar", "pan-prime", "bank-account-prime"];
    let empty = true;
    govtCheck.forEach((el) => {
      if (this.state.updateData[el] && this.state.updateData[el] > 0) {
        empty = false;
      }
    })
    if (!empty) {
      this.setState({
        services: { ...this.state.services, gc: true },
        openGovtCheckDialog: false,
      })
    }
    return

  }

  changeLimit = e => {
    const { name, value } = e.target;
    const regex = /^[0-9]*$/;
    if (regex.test(value))
      this.setState({ updateData: { ...this.state.updateData, [name]: value } });
  }

  handleChange = (event) => {
    if (event.target.name === "aadhaarXml" && !this.state.services.aadhaarXml) {
      this.setState({ openXmlDialog: true });
    } else if (
      event.target.name === "videoKyc" &&
      !this.state.services.videoKyc
    ) {
      this.setState({ openKycDialog: true });
    } else if (
      event.target.name === "linkBased" &&
      !this.state.services.linkBased
    ) {
      this.setState({ openLinkBasedDialog: true });
    } else if (event.target.name === "digilocker" && !this.state.services.digilocker) {
      this.setState({ openDigilockerDialog: true });
    } else if (event.target.name === "emailOtp" && !this.state.services.emailOtp) {
      this.setState({ openEmailOtpDialog: true });
    } else if (event.target.name === "selfieMatch" && !this.state.services.selfieMatch) {
      this.setState({ openSelfieMatchDialog: true });
    } else if (event.target.name === "sendEmail" && !this.state.services.sendEmail) {
      this.setState({ openSendEmailDialog: true });
    } else if (event.target.name === "gc" && !this.state.services.gc) {
      this.setState({ openGovtCheckDialog: true });
    } else if (event.target.name === "bankStatement" && !this.state.services.bankStatement) {
      this.setState({ openBankStatementDialog: true });
    } else if (event.target.name === "upiDetails" && !this.state.services.upiDetails) {
      this.setState({ openUpiDetails: true });
    } else if (event.target.name === "smsOtp" && !this.state.services.smsOtp) {
      this.setState({ openSmsOtpDialog: true });

    } else {
      this.setState({
        services: {


          ...this.state.services,
          [event.target.name]: event.target.checked,
        },
      });
    }
  };

  handleDialog = (service) => {
    if (service === "aadhaarXml") this.setState({ openXmlDialog: false });
    else if (service === "digilocker") this.setState({ openDigilockerDialog: false });
    else if (service === "kyc") this.setState({ openKycDialog: false });
    else if (service === "linkBased") this.setState({ openLinkBasedDialog: false });
    else if (service === "emailOtp") this.setState({ openEmailOtpDialog: false });
    else if (service === "selfieMatch") this.setState({ openSelfieMatchDialog: false });
    else if (service === "sendEmail") this.setState({ openSendEmailDialog: false });
    else if (service === "bankStatement") this.setState({ openBankStatementDialog: false });
    else if (service === "upiDetails") this.setState({ openUpiDetails: false });
    else if (service === "govtCheck") this.setState({ openGovtCheckDialog: false });
    else if (service === "smsOtp") this.setState({ openSmsOtpDialog: false });
    else this.setState({ openSubmitDialog: false });
  };

  changeImage = (e) => {
    this.setState({ image: e.target.files[0] });
  };

  changeLinkBasedImage = (e) => {
    this.setState({ linkBasedImage: e.target.files[0] });
  };

  onDigilockerDocChange = (event) => {
    const digilockerDoctype = this.state.digilockerDoctype
    if (event.target.name === "digilockerAadhaar") {
      digilockerDoctype.aadhaar = !digilockerDoctype.aadhaar

    } else if (event.target.name === "digilockerPan") {
      digilockerDoctype.pan = !digilockerDoctype.pan

    } else if (event.target.name === "digilockerDl") {
      digilockerDoctype.dl = !digilockerDoctype.dl
    }

    this.setState({
      digilockerDoctype: digilockerDoctype
    });
  }


  onDigilockerAccountTypeChange = (event) => {
    this.setState({
      digilockerAccountType: event.target.value
    })

  }

  generateDigilockerPassword() {
    var pass = '';
    var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
      'abcdefghijklmnopqrstuvwxyz0123456789@#$';

    for (let i = 1; i <= 15; i++) {
      var char = Math.floor(Math.random()
        * str.length + 1);

      pass += str.charAt(char)
    }

    return pass;
  }

  render() {
    const {
      name,
      authkey,
      bucketName,
      userName,
      password,
      openSubmitDialog,
      openXmlDialog,
      openKycDialog,
      openDigilockerDialog,
      color,
      digilockerPrimaryColor,
      digilockerSecondaryColor,
      digilockerDoctype,
      digilockerAccountType,
      videoKycDetails,
      image,
      openLinkBasedDialog,
      linkBasedColor,
      linkBasedImage,
      loading,
      openEmailOtpDialog,
      emailOtpDetails,
      openSelfieMatchDialog,
      selfieMatchDetails,
      openSendEmailDialog,
      sendEmailDetails
    } = this.state;
    const { admin, agent, checker } = this.state.videoKycDetails;
    const { fv, ic, gc, masking, aadhaarXml, videoKyc, linkBased, bankStatement, upiDetails, liveness, digilocker, emailOtp, selfieMatch, sendEmail,
      androidAadhaarXmlSdk, androidLivenessAndImageHelperSdk, smsOtp } = this.state.services;
    const govtCheck = ["dl", "pan", "pan-lite", "aadhaar", "passport", "voter", "gst-advance", "gst-lite", "rc",
      "rc-lite", "rc-prime", "bank-account", "offline-aadhaar", "pan-supreme", "dl-prime",
      "udyog-aadhaar", "pan-prime", "bank-account-prime"];


    return (
      <div className="create-user-container">
        {openSubmitDialog && (
          <Dialog
            open={openSubmitDialog ? true : false}
            onClose={() => this.handleDialog("submit")}
          >
            <DialogTitle>{"User Details:"}</DialogTitle>
            <DialogContent>
              <DialogContentText>Name : {name}</DialogContentText>
              <DialogContentText>Bucket Name : {bucketName}</DialogContentText>
              <DialogContentText>User Name : {userName}</DialogContentText>
              <DialogContentText>Password : {password}</DialogContentText>
              <DialogContentText>Authkey : {authkey}</DialogContentText>
              <DialogContentText>Services allowed :
                <ul>
                  <li>Name Match</li>
                  {(fv) && <li>Face Verifiation</li>}
                  {(ic) && <li>ID Check</li>}
                  {(gc) && <li>Government Database Check</li>}
                  {(masking) && <li>Aadhaar Masking</li>}
                  {(aadhaarXml) && <li>Aadhaar XML</li>}
                  {(videoKyc) && <li>Video KYC</li>}
                  {(linkBased) && <li>Link Based Verification</li>}
                  {(liveness) && <li>Liveness</li>}
                  {(digilocker) && <li>Digilocker</li>}
                  {(selfieMatch) && <li>Selfie Match</li>}
                  {(emailOtp) && <li>Email Otp</li>}
                  {(sendEmail) && <li>Send Email</li>}
                  {(androidAadhaarXmlSdk) && <li>Android Aadhaar XML Sdk</li>}
                  {(androidLivenessAndImageHelperSdk) && <li>Android Liveness and ImageHelper Sdk</li>}
                  {(bankStatement) && <li>Bank Statement</li>}
                  {(upiDetails) && <li>UPI details</li>}
                  {(smsOtp) && <li>SMS Otp</li>}
                </ul>
              </DialogContentText>
              {digilocker && (
                <DialogContentText>Digilocker Password : {this.state.digilockerPassword}</DialogContentText>
              )}
              {videoKyc && (
                <>
                  <DialogContentText>
                    Admin Creds:
                    <DialogContentText>
                      Username: {videoKycDetails.admin.email}
                    </DialogContentText>
                    <DialogContentText>
                      Password: {videoKycDetails.admin.pass}
                    </DialogContentText>
                  </DialogContentText>
                  <DialogContentText>
                    Checker Creds:
                    <DialogContentText>
                      Username: {videoKycDetails.checker.email}
                    </DialogContentText>
                    <DialogContentText>
                      Password: {videoKycDetails.checker.pass}
                    </DialogContentText>
                  </DialogContentText>
                  <DialogContentText>
                    Agent Creds:
                    <DialogContentText>
                      Username: {videoKycDetails.agent.email}
                    </DialogContentText>
                    <DialogContentText>
                      Password: {videoKycDetails.agent.pass}
                    </DialogContentText>
                  </DialogContentText>
                </>
              )}
              {/* <DialogContentText>
                Services Included:
                {Object.keys(this.state.services).map((x, idx) => {
                  return (
                    <DialogContentText style={{ marginLeft: "20px" }} key={idx}>
                      {x} : {this.state.services[x]}
                    </DialogContentText>
                  );
                })}
              </DialogContentText> */}
            </DialogContent>
          </Dialog>
        )}

        <h1>Create New User</h1>
        <form className="user-form" noValidate>
          <TextField
            classes={{ root: "name" }}
            variant="outlined"
            required
            label="Bucket Name (must not contain spaces or uppercase letters)"
            autoFocus
            value={bucketName}
            onChange={(e) => this.setState({ bucketName: e.target.value })}
          />
          <TextField
            classes={{ root: "name" }}
            variant="outlined"
            required
            label="Username (for dashboard)"
            value={userName}
            onChange={(e) => this.setState({ userName: e.target.value })}
          />
          <TextField
            classes={{ root: "name" }}
            variant="outlined"
            required
            label="Name (for dashboard)"
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
          <TextField
            classes={{ root: "name" }}
            variant="outlined"
            required
            label="Password (for dashboard)"
            type="password"
            value={password || ""}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </form>
        <FormControl component="fieldset">
          <FormLabel component="legend">Services</FormLabel>
          <FormGroup>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
              <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={true} />
                  }
                  label="Name Match"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={fv} onChange={this.handleChange} name="fv" />
                  }
                  label="Face Verification"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={ic} onChange={this.handleChange} name="ic" />
                  }
                  label="ID Check"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={gc} onChange={this.handleChange} name="gc" />
                  }
                  label="Government Database Check"
                />
                {this.state.openGovtCheckDialog && (
                  <>
                    <Dialog
                      open={this.state.openGovtCheckDialog ? true : false}
                      onClose={() => this.handleDialog("govtCheck")}
                    >
                      <div
                        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", padding: "1rem" }}
                      >
                        {govtCheck.map((doctype, idx) => (
                          <DialogContent>
                            <TextField
                              key={idx}
                              classes={{ root: "limit-input" }}
                              variant="outlined"
                              label={`${doctype} limit`}
                              name={doctype}
                              type="number"
                              autoFocus
                              value={this.state.updateData[doctype]}
                              onChange={e => this.changeLimit(e)}
                            />
                          </DialogContent>
                        ))}
                        <DialogActions>
                          <Button
                            onClick={() => this.handleDialog("govtCheck")}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={this.handleGovtCheckSubmit}
                            color="primary"
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </div>
                    </Dialog>
                  </>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={masking}
                      onChange={this.handleChange}
                      name="masking"
                    />
                  }
                  label="Aadhaar Masking"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={aadhaarXml}
                      onChange={this.handleChange}
                      name="aadhaarXml"
                    />
                  }
                  label="Aadhaar XML"
                />
                {openXmlDialog && (
                  <Dialog
                    open={openXmlDialog ? true : false}
                    onClose={() => this.handleDialog("aadhaarXml")}
                  >
                    <DialogTitle>XML Details:</DialogTitle>
                    <DialogContent>
                      <Input
                        type="file"
                        onChange={this.changeImage}
                        accept="image/*"
                      />
                    </DialogContent>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        label="Color"
                        value={color || ""}
                        onChange={(e) => this.setState({ color: e.target.value })}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("aadhaarXml")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, aadhaarXml: true },
                            openXmlDialog: false,
                          })
                        }
                        color="primary"
                        disabled={!image}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={linkBased}
                      onChange={this.handleChange}
                      name="linkBased"
                    />
                  }
                  label="Link Based Verification"
                />
                {openLinkBasedDialog && (
                  <Dialog
                    open={openLinkBasedDialog ? true : false}
                    onClose={() => this.handleDialog("linkBased")}
                  >
                    <DialogTitle>Link Based Details:</DialogTitle>
                    <DialogContent>
                      <Input
                        type="file"
                        onChange={this.changeLinkBasedImage}
                        accept="image/*"
                      />
                    </DialogContent>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        label="Color"
                        value={linkBasedColor || ""}
                        onChange={(e) => this.setState({ linkBasedColor: e.target.value })}
                      />
                    </DialogContent>
                    <DialogContent>
                      <h5>For this to work, please make sure to give Face Verification, ID Check and Government Database Check permission to user.</h5>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("linkBased")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, linkBased: true },
                            openLinkBasedDialog: false,
                          })
                        }
                        color="primary"
                        disabled={!linkBasedImage}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={videoKyc}
                      onChange={this.handleChange}
                      name="videoKyc"
                    />
                  }
                  label="Video KYC"
                />
                {openKycDialog && (
                  <Dialog
                    open={openKycDialog ? true : false}
                    onClose={() => this.handleDialog("kyc")}
                  >
                    <DialogTitle>Fill User Details:</DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        style={{ fontWeight: "bolder", color: "black" }}
                      >
                        Admin Details
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="adminname"
                        label="Enter Name"
                        fullWidth
                        value={admin.name}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              admin: { ...admin, name: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        id="adminemail"
                        label="Enter Email Address"
                        type="email"
                        fullWidth
                        value={admin.email}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              admin: { ...admin, email: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        id="adminpass"
                        label="Enter Password"
                        type="password"
                        fullWidth
                        value={admin.pass}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              admin: { ...admin, pass: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        error={
                          admin.pass.length &&
                          admin.confirm.length &&
                          admin.confirm !== admin.pass
                        }
                        autoFocus
                        margin="dense"
                        id="adminconfirm"
                        label="Enter Password Again"
                        type="password"
                        fullWidth
                        helperText="Please enter password same as above"
                        value={admin.confirm}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              admin: { ...admin, confirm: e.target.value },
                            },
                          })
                        }
                      />
                      <br />
                      <br />
                      <br />
                      <DialogContentText
                        style={{ fontWeight: "bolder", color: "black" }}
                      >
                        Checker Details
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="checkername"
                        label="Enter Name"
                        fullWidth
                        value={checker.name}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              checker: { ...checker, name: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        id="checkeremail"
                        label="Enter Email Address"
                        type="email"
                        fullWidth
                        value={checker.email}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              checker: { ...checker, email: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        id="checkerpass"
                        label="Enter Password"
                        type="password"
                        fullWidth
                        value={checker.pass}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              checker: { ...checker, pass: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        error={
                          checker.pass.length &&
                          checker.confirm.length > 0 &&
                          checker.confirm !== checker.pass
                        }
                        autoFocus
                        margin="dense"
                        id="checkerconfirm"
                        label="Enter Password Again"
                        type="password"
                        helperText="Please enter password same as above"
                        fullWidth
                        value={checker.confirm}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              checker: { ...checker, confirm: e.target.value },
                            },
                          })
                        }
                      />
                      <br />
                      <br />
                      <br />
                      <DialogContentText
                        style={{ fontWeight: "bolder", color: "black" }}
                      >
                        Agent Details
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="agentname"
                        label="Enter Name"
                        fullWidth
                        value={agent.name}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              agent: { ...agent, name: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        id="agentemail"
                        label="Enter Email Address"
                        type="email"
                        fullWidth
                        value={agent.email}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              agent: { ...agent, email: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        id="agentpass"
                        label="Enter Password"
                        type="password"
                        fullWidth
                        value={agent.pass}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              agent: { ...agent, pass: e.target.value },
                            },
                          })
                        }
                      />
                      <TextField
                        error={
                          agent.pass.length &&
                          agent.confirm.length > 0 &&
                          agent.confirm !== agent.pass
                        }
                        autoFocus
                        margin="dense"
                        id="agentconfirm"
                        label="Enter Password Again"
                        helperText="Please enter password same as above"
                        type="password"
                        fullWidth
                        value={agent.confirm}
                        onChange={(e) =>
                          this.setState({
                            videoKycDetails: {
                              ...videoKycDetails,
                              agent: { ...agent, confirm: e.target.value },
                            },
                          })
                        }
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("kyc")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setState({
                            services: { ...this.state.services, videoKyc: true },
                          });
                          this.handleDialog("kyc");
                        }}
                        color="primary"
                        disabled={
                          !admin.name ||
                          !admin.email ||
                          !admin.pass ||
                          !admin.confirm ||
                          !agent.name ||
                          !agent.email ||
                          !agent.pass ||
                          !agent.confirm ||
                          !checker.name ||
                          !checker.email ||
                          !checker.pass ||
                          !checker.confirm
                        }
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={liveness}
                      onChange={this.handleChange}
                      name="liveness"
                    />
                  }
                  label="Liveness"
                />
              </div>

              <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={digilocker}
                      onChange={this.handleChange}
                      name="digilocker"
                    />
                  }
                  label="Digilocker"
                />
                {openDigilockerDialog && (
                  <Dialog
                    open={openDigilockerDialog ? true : false}
                    onClose={() => this.handleDialog("digilocker")}
                  >
                    <DialogTitle>Digilocker Details:</DialogTitle>
                    <DialogContent>
                      <Input
                        type="file"
                        onChange={this.changeImage}
                        accept="image/*"
                      />
                    </DialogContent>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        label="Primary Color"
                        value={digilockerPrimaryColor || ""}
                        onChange={(e) => this.setState({ digilockerPrimaryColor: e.target.value })}
                      />
                      <TextField
                        variant="outlined"
                        required
                        label="Secondary Color"
                        value={digilockerSecondaryColor || ""}
                        onChange={(e) => this.setState({ digilockerSecondaryColor: e.target.value })}
                      />

                    </DialogContent>
                    <DialogContent>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Doc Type</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox checked={digilockerDoctype.aadhaar} onChange={this.onDigilockerDocChange} name="digilockerAadhaar" />
                            }
                            label="Aadhaar"
                          />
                          <FormControlLabel
                            control={
                              // <Checkbox checked={fv} onChange={this.handleChange} name="fv" />
                              <Checkbox checked={digilockerDoctype.pan} onChange={this.onDigilockerDocChange} name="digilockerPan" />
                            }
                            label="Pan"
                          />
                          <FormControlLabel
                            control={
                              // <Checkbox checked={fv} onChange={this.handleChange} name="fv" />
                              <Checkbox checked={digilockerDoctype.dl} onChange={this.onDigilockerDocChange} name="digilockerDl" />
                            }
                            label="DL"
                          />
                        </FormGroup>
                      </FormControl>
                    </DialogContent>


                    <DialogContent>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Account Type</FormLabel>
                        <RadioGroup row name="accountType" value={digilockerAccountType} onChange={this.onDigilockerAccountTypeChange}>
                          <FormControlLabel
                            value="dev"
                            control={
                              <Radio />
                            }
                            label="Development"
                          />
                          <FormControlLabel
                            value="prod"
                            control={
                              <Radio />
                            }
                            label="Production"
                          />
                        </RadioGroup>
                      </FormControl>
                    </DialogContent>

                    {this.state.digilockerAccountType === "prod" &&

                      <DialogContent>
                        <TextField
                          variant="outlined"
                          required
                          label="Webhook"
                          value={this.state.digilockerWebhook}
                          onChange={(e) => this.setState({ digilockerWebhook: e.target.value })}
                        />

                      </DialogContent>
                    }

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("digilocker")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, digilocker: true },
                            openDigilockerDialog: false,
                          })
                        }
                        color="primary"
                        disabled={!image}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selfieMatch}
                      onChange={this.handleChange}
                      name="selfieMatch"
                    />
                  }
                  label="Selfie Match"
                />
                {openSelfieMatchDialog && (
                  <Dialog
                    open={openSelfieMatchDialog ? true : false}
                    onClose={() => this.handleDialog("selfieMatch")}
                  >
                    <DialogTitle>Selfie Match Details:</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        type="number"
                        label="Limit"
                        value={selfieMatchDetails.limit || ""}
                        onChange={(e) => {
                          const temp = selfieMatchDetails
                          temp.limit = e.target.value
                          this.setState({
                            selfieMatchDetails: temp
                          })

                        }}
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("selfieMatch")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, selfieMatch: true },
                            openSelfieMatchDialog: false,
                          })
                        }
                        color="primary"
                        disabled={!selfieMatchDetails.limit}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}



                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailOtp}
                      onChange={this.handleChange}
                      name="emailOtp"
                    />
                  }
                  label="Email Otp"
                />
                {openEmailOtpDialog && (
                  <Dialog
                    open={openEmailOtpDialog ? true : false}
                    onClose={() => this.handleDialog("emailOtp")}
                  >
                    <DialogTitle>Email Otp Details:</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        type="number"
                        label="Limit"
                        value={emailOtpDetails.limit || ""}
                        onChange={(e) => {
                          const temp = emailOtpDetails
                          temp.limit = e.target.value
                          this.setState({
                            emailOtpDetails: temp
                          })

                        }}
                      />
                    </DialogContent>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        label="Sender Email"
                        value={emailOtpDetails.senderEmail || ""}
                        onChange={(e) => {
                          const temp = emailOtpDetails
                          temp.senderEmail = e.target.value
                          this.setState({
                            emailOtpDetails: temp
                          })

                        }}
                      />

                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("emailOtp")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, emailOtp: true },
                            openEmailOtpDialog: false,
                          })
                        }
                        color="primary"
                        disabled={!emailOtpDetails.limit || !emailOtpDetails.senderEmail}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}


                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendEmail}
                      onChange={this.handleChange}
                      name="sendEmail"
                    />
                  }
                  label="Send Email"
                />
                {openSendEmailDialog && (
                  <Dialog
                    open={openSendEmailDialog ? true : false}
                    onClose={() => this.handleDialog("sendEmail")}
                  >
                    <DialogTitle>Send Email Details:</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        type="number"
                        label="Limit"
                        value={sendEmailDetails.limit || ""}
                        onChange={(e) => {
                          const temp = sendEmailDetails
                          temp.limit = e.target.value
                          this.setState({
                            sendEmailDetails: temp
                          })

                        }}
                      />
                    </DialogContent>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        label="Sender Email"
                        value={sendEmailDetails.senderEmail || ""}
                        onChange={(e) => {
                          const temp = sendEmailDetails
                          temp.senderEmail = e.target.value
                          this.setState({
                            sendEmailDetails: temp
                          })

                        }}
                      />

                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("sendEmail")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, sendEmail: true },
                            openSendEmailDialog: false,
                          })
                        }
                        color="primary"
                        disabled={!sendEmailDetails.limit || !sendEmailDetails.senderEmail}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={androidAadhaarXmlSdk}
                      onChange={this.handleChange}
                      name="androidAadhaarXmlSdk"
                    />
                  }
                  label="Android Aadhaar XML Sdk"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={androidLivenessAndImageHelperSdk}
                      onChange={this.handleChange}
                      name="androidLivenessAndImageHelperSdk"
                    />
                  }
                  label="Android Liveness and ImageHelper Sdk"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.services.bankStatement}
                      onChange={this.handleChange}
                      name="bankStatement"
                    />
                  }
                  label="Bank Statement"
                />
                {this.state.openBankStatementDialog && (
                  <Dialog
                    open={this.state.openBankStatementDialog ? true : false}
                    onClose={() => this.handleDialog("bankStatement")}
                  >
                    <DialogTitle>Bank Statement Details:</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        type="number"
                        label="Limit"
                        value={this.state.bankStatementDetails.limit || ""}
                        onChange={(e) => {
                          this.setState({
                            bankStatementDetails: { ...this.state.bankStatementDetails, limit: e.target.value }
                          })

                        }}
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("bankStatement")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, bankStatement: true },
                            openBankStatementDialog: false,
                          })
                        }
                        color="primary"
                        disabled={!this.state.bankStatementDetails.limit}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}



                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.services.upiDetails}
                      onChange={this.handleChange}
                      name="upiDetails"
                    />
                  }
                  label="UPI details"
                />
                {this.state.openUpiDetails && (
                  <Dialog
                    open={this.state.openUpiDetails ? true : false}
                    onClose={() => this.handleDialog("upiDetails")}
                  >
                    <DialogTitle>UPI Details:</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        type="number"
                        label="Limit"
                        value={this.state.upiDetails.limit || ""}
                        onChange={(e) => {
                          this.setState({
                            upiDetails: { ...this.state.upiDetails, limit: e.target.value }
                          })

                        }}
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("upiDetails")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, upiDetails: true },
                            openUpiDetails: false,
                          })
                        }
                        color="primary"
                        disabled={!this.state.upiDetails.limit}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}






                {/* Sms OTP */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.services.smsOtp}
                      onChange={this.handleChange}
                      name="smsOtp"
                    />
                  }
                  label="Sms Otp"
                />
                {this.state.openSmsOtpDialog && (
                  <Dialog
                    open={this.state.openSmsOtpDialog ? true : false}
                    onClose={() => this.handleDialog("smsOtp")}
                    fullWidth={true}
                    maxWidth={'lg'}
                  >
                    <DialogTitle>Sms Otp:</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        required
                        type="number"
                        label="Limit"
                        value={this.state.smsOtpDetails.limit || ""}
                        onChange={(e) => {
                          this.setState({
                            smsOtpDetails: { ...this.state.smsOtpDetails, limit: e.target.value }
                          })

                        }}
                      />
                    </DialogContent>

                    <DialogContent>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Template Id</TableCell>
                              <TableCell >Template Info</TableCell>
                              <TableCell >Template Message</TableCell>
                              <TableCell >Sms Sender code</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.smsOtpDetails.template.map((template) => (
                              <TableRow key={template.templateId}>
                                <TableCell component="th" scope="row">
                                  {template.templateId}
                                </TableCell>
                                <TableCell >{template.templateInfo}</TableCell>
                                <TableCell >{template.templateMessage}</TableCell>
                                <TableCell >{template.smsSender}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell>
                                <TextField id='templateId'
                                  variant="outlined"
                                  required
                                  label="Template Id"

                                />
                              </TableCell>
                              <TableCell>
                                <TextField id='templateInfo'
                                  variant="outlined"
                                  required
                                  label="Template Info"

                                />
                              </TableCell>
                              <TableCell>
                                <TextField id='templateMessage'
                                  variant="outlined"
                                  required
                                  label="Template Message"

                                />
                              </TableCell>
                              <TableCell>
                                <TextField id='templateSmsSender'
                                  variant="outlined"
                                  required
                                  label="Sms Sender"

                                />
                              </TableCell>

                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const templateIdElement = document.getElementById('templateId')
                                    const templateInfoElement = document.getElementById('templateInfo')
                                    const templateMessageElement = document.getElementById('templateMessage')
                                    const templateSmsSenderElement = document.getElementById('templateSmsSender')

                                    const templateId = templateIdElement.value
                                    const templateInfo = templateInfoElement.value
                                    const templateMessage = templateMessageElement.value
                                    const smsSender = templateSmsSenderElement.value

                                    const newTemplate = { templateId, templateInfo, templateMessage, smsSender }
                                    const templates = this.state.smsOtpDetails.template
                                    templates.push(newTemplate)

                                    templateIdElement.value = ''
                                    templateInfoElement.value = ''
                                    templateMessageElement.value = ''
                                    templateSmsSenderElement.value = ''


                                    this.setState({
                                      smsOtpDetails: { ...this.state.smsOtpDetails, template: templates }

                                    })

                                  }}
                                >
                                  Add
                                </Button>
                              </TableCell>

                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => this.handleDialog("smsOtp")}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            services: { ...this.state.services, smsOtp: true },
                            openSmsOtpDialog: false,
                          })
                        }
                        color="primary"
                        disabled={(this.state.smsOtpDetails.limit <= 0 || this.state.smsOtpDetails.template.length <= 0)}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </div>
            </div>
          </FormGroup>
        </FormControl>
        <Button
          className="submit-btn"
          size="medium"
          variant="contained"
          color="primary"
          onClick={this.createBucket}
          disabled={!bucketName || !userName || !name || !password || loading}
        >
          {loading ? <CircularProgress style={{ color: '#fff', fontSize: '2rem' }} /> : 'Create User'}
        </Button>
      </div>
    );
  }
}

export default withRouter(NewUser);
