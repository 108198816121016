import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Button,
    CircularProgress,
    RadioGroup,
    Radio
} from "@material-ui/core";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { sub } from 'date-fns'
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import axios from 'axios';
import { CSVLink } from "react-csv";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

class DeleteUser extends Component {

    state = {
        loading: false,
        deleteLoading: false,
        users: null,
        selectedUsers: [],
        deleteConfimationDialog: false,
        usersType: '0',
        durationValue: '1',
        customDuration: Date.now(),
        csvLoading: false,
        csvError: false,
        csvData: null,
        openCsvDialog: false
    }

    fetchInactiveUsersList = async () => {

        try {

            this.setState({
                loading: true
            });


            const { authkey } = this.props;
            const payload = new FormData();
            payload.append('active', this.state.usersType)

            let epochTime;

            if (this.state.durationValue === '4') {
                epochTime = this.state.customDuration
            } else {

                let month = 6;
                if (this.state.durationValue === '2') {
                    month = 3
                } else if (this.state.durationValue === '3') {
                    month = 1
                }

                epochTime = sub(new Date(), { months: month }).getTime()
            }
            payload.append('epochTime', epochTime)

            let res = await axios.post(`${process.env.REACT_APP_API_URL}/users-status`, payload, { headers: { authkey } });
            if (res && res.data && res.data.data) {
                const inactiveUsers = res.data.data
                let data = []
                inactiveUsers.forEach((item, index) => {
                    data.push({
                        authkey: item.authkey,
                        name: item.name,
                        checked: false
                    })
                })
                this.setState({ users: data, loading: false });

            } else if (res && res.data && res.data.message) {
                alert(res.data.message);
            } else alert('Something Went Wrong');
        } catch (e) {
            alert(e.message)
        }
        this.setState({
            loading: false
        });
    }

    onUsersTypeChange = (event) => {
        this.setState({
            usersType: event.target.value
        })
    }

    handleDuration = (event, newDurationValue) => {
        this.setState({
            durationValue: newDurationValue
        })
        if (newDurationValue === "4") {
            this.setState({
                customDuration: Date.now()
            })
        }
    }

    downloadCsv = async () => {


        try {

            this.setState({
                csvLoading: true
            });

            const { authkey } = this.props;
            const payload = new FormData();
            payload.append('active', this.state.usersType)

            let epochTime;

            if (this.state.durationValue === '4') {
                epochTime = this.state.customDuration
            } else {

                let month = 6;
                if (this.state.durationValue === '2') {
                    month = 3
                } else if (this.state.durationValue === '3') {
                    month = 1
                }

                epochTime = sub(new Date(), { months: month }).getTime()
            }
            payload.append('epochTime', epochTime)

            let res = await axios.post(`${process.env.REACT_APP_API_URL}/user-active-services`, payload, { headers: { authkey } });
            if (res && res.data && res.data.data) {
                var data = res.data.data
                this.setState({
                    csvLoading: false,
                    csvError: false,
                    csvData: data
                }, () => {
                    setTimeout(() => {
                        const csvButton = document.getElementById('downloadCsv')
                        if (csvButton) {
                            csvButton.click()
                        }
                    }, 100)
                })

            } else if (res && res.data && res.data.message) {
                alert(res.data.message);
            } else alert('Something Went Wrong');
        } catch (e) {
            alert(e.message)
        }
        this.setState({
            csvLoading: false
        });
    }

    handleDialog = (name) => {
        if(name === "csvDialog") {
            this.setState({
                openCsvDialog: false
            })
        }

    }

    AsyncCsvButton(props) {

        if (props.loading) {
            return null
        }

        if (props.csvLoading) {
            return <button style={{ margin: "0px 0px 0px 40px" }} className="downloadButton" disabled >Downloading...</button>
        } else {
            if (props.csvError) {
                return <button style={{ margin: "0px 0px 0px 40px" }} className="downloadButton" onClick={props.downloadCsv}>Services used by Clients (CSV)</button>
            }

            if (props.csvData === null) {
                return <button style={{ margin: "0px 0px 0px 40px" }} className="downloadButton" onClick={props.downloadCsv}>Services used by Clients (CSV)</button>
            }

            const headers = [
                { label: "Authkey", key: "authkey" },
                { label: "Name", key: "name" },
                { label: "FV", key: "fv" },
                { label: "IC", key: "ic" },
                { label: "Govt Check", key: "governmentCheck" },
                { label: "Video KYC", key: "videoKyc" },
                { label: "Web Ocr", key: "webocr" },
                { label: "XML", key: "xml" },
                { label: "Name Match", key: "namematch" },
                { label: "Digilocker", key: "digilocker" },
                { label: "Livenesss", key: "liveness" },
                { label: "Card Liveness", key: "cardLiveness" },
                { label: "Photocopy", key: "photocopy" },
                { label: "Email OTP", key: "emailOtp" },
                { label: "Send Email", key: "sendEmail" },
                { label: "Send Message", key: "sendMessage" },
                { label: "Sms Otp", key: "smsOtp" },
                { label: "Bankstatement Parsing", key: "bankStatementParsing" },
                { label: "UPI Details", key: "upiDetails" },
                { label: "Pincode", key: "pincode" },
                { label: "IFSC", key: "ifsc" },
            ];
            return <CSVLink style={{ margin: "0px 0px 0px 40px" }}
                id="downloadCsv"
                data={props.csvData}
                headers={headers}
                filename={`client_services.csv`}
                className="downloadButton"
            >Services used by Clients (CSV)</CSVLink>


        }

    }

    AsynCsvButtonDialog(props) {
        return <Dialog onClose={props.handleDialog} aria-labelledby="customized-dialog-title" open={props.openCsvDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleDialog}>
                Information
            </DialogTitle>
            <DialogContent dividers>
                Download CSV file containing list of users and the services they have used during the selected time period
            </DialogContent>
        </Dialog>
    }



    render() {
        const { loading, users, usersType, durationValue, customDuration, openCsvDialog } = this.state
        return <div style={{ padding: "24px", boxSizing: "border-box", width: "100vw" }}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Choose user status</FormLabel>
                <RadioGroup row name="accountType" value={usersType} onChange={this.onUsersTypeChange}>
                    <FormControlLabel
                        value="0"
                        control={
                            <Radio />
                        }
                        label="Inactive"
                    />
                    <FormControlLabel
                        value="1"
                        control={
                            <Radio />
                        }
                        label="Active"
                    />
                </RadioGroup>
            </FormControl>
            <div style={{ margin: "8px 0px 0px 0px" }}>
                <ToggleButtonGroup
                    value={durationValue}
                    exclusive
                    onChange={this.handleDuration}
                    aria-label="Duration">
                    <ToggleButton value="1">
                        6 Months
                    </ToggleButton>
                    <ToggleButton value="2">
                        3 Months
                    </ToggleButton>
                    <ToggleButton value="3">
                        Last Month
                    </ToggleButton>
                    <ToggleButton value="4">
                        Custom
                    </ToggleButton>
                </ToggleButtonGroup>
                {durationValue === '4' &&
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker value={customDuration} style={{ margin: "8px 0px 0px 8px" }} onChange={(value) => {
                            let date = new Date(value)
                            this.setState({
                                customDuration: date.getTime()
                            })
                        }} />
                    </MuiPickersUtilsProvider>}
                <this.AsyncCsvButton
                    loading={this.state.loading}
                    csvLoading={this.state.csvLoading}
                    csvError={this.state.csvError}
                    csvData={this.state.csvData}
                    downloadCsv={this.downloadCsv}
                />
                {this.state.openCsvDialog && <this.AsynCsvButtonDialog
                    handleDialog={() => this.handleDialog("csvDialog")}
                    openCsvDialog={this.state.openCsvDialog}

                />}
                
                <Button
                    onClick={() => {
                        console.log("clicked")
                        this.setState({
                            openCsvDialog: true
                        })
                    }
                    }
                    color="primary"
                >
                    <InfoOutlinedIcon style={{ color: "#4CAF50" }} />
                </Button>

            </div>
            <div style={{ margin: "16px 0px 0px 0px" }}>
                <Button
                    className="submit-btn"
                    size="medium"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={this.fetchInactiveUsersList}
                >
                    Continue
                </Button>
            </div>

            {loading && <div>
                <CircularProgress style={{ color: '#3066F0', margin: "16px 0px 16px 0px" }} size={30} />
            </div>}

            <div>
                {users !== null && !loading &&
                    <>
                        {usersType === "0" ? <p>Inactive users </p> : <p>Active users</p>}


                        <div className="flex-container">
                            {users.map((user, index) => (
                                <div className="flex-child">
                                    <li>{user.name}</li>
                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>
        </div>
    }

}

export default withRouter(DeleteUser);