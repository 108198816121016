import React from "react";
import { CSVLink } from "react-csv";
import '../../styles/billing/app.css';
import {getDisplayName, getClientDisplayName} from "./utils"

function AsyncCsvButton(props) {

    if (props.csvLoading) {
        return <button className="downloadButton" disabled >Downloading...</button>
    } else {
        if (props.csvError) {
            return <button className="downloadButton" onClick = {props.csvOnClick}>Download CSV</button>
        }

        if(props.csvData === null) {
            return <button className="downloadButton" onClick = {props.csvOnClick}>Download CSV</button>
        }

        if (props.serviceName === 'governmentCheck') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },
                { label: "govtCheckStatus", key: "govtCheckStatus" },
                { label: "Doc Number", key: "docNumber" },
                { label: "Doc Type", key: "docType" },
                { label: "Name", key: "name" },
                { label: "Dob", key: "dob" },
                { label: "Address", key: "address" },
                { label: "Name Similarity (form-govt)", key: "nameSim(form-govt)" },
                { label: "Name Similarity (form-govt)", key: "nameSim(govt-form)" },
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink>
        }

        if (props.serviceName === 'ic') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Doc Detected", key: "docDetected" },
                { label: "Status", key: "status" },
                
                { label: "Doc Number", key: "documentNumber" },
                
                { label: "Name", key: "name" },
                { label: "Name Similarity (form-govt)", key: "nameSim(form-govt)" },
                { label: "Name Similarity Match (form-govt)", key: "nameSimMatch(form-govt)" },
                { label: "Name Similarity (doc-form)", key: "nameSim(doc-form)" },
                { label: "Name Similarity Match (doc-form)", key: "nameSimMatch(doc-form)" },
                { label: "Name Similarity (doc-govt)", key: "nameSim(doc-govt)" },
                { label: "Name Similarity Match (doc-govt)", key: "nameSimMatch(doc-govt)" },
                { label: "Gender", key:"gender"},
                { label: "Dob", key: "dateOfBirth" },
                { label: "Dob Similarity (form-govt)", key: "dobSim(form-govt)" },
                { label: "Dob Similarity (doc-form)", key: "dobSim(doc-form)" },
                { label: "Dob Similarity (doc-govt)", key: "dobSim(doc-govt)" },

                { label: "Parent Name", key: "parentName" },
                { label: "Address", key: "address" },

                { label: "GC Status", key: "gcStatus" },
                { label: "Govt Check Status", key: "govtCheckStatus" },
                { label: "GC Name", key: "gcName" },
                { label: "GC Dob", key: "gcDob" },

                { label: "Face Confidence", key: "faceConfidence" },
                { label: "Blur Confidence", key: "faceBlurConfidence" },
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink>
        }

        if (props.serviceName === 'fv') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },
                
                { label: "Doc Detected", key: "docDetected" },
                { label: "From Doc Type", key: "form-doctype" },
                { label: "Doc Number", key: "documentNumber" },
                
                { label: "User ID", key: "userId" },
                
                { label: "Name", key: "name" },
                { label: "Name Similarity (form-govt)", key: "nameSim(form-govt)" },
                { label: "Name Similarity Match (form-govt)", key: "nameSimMatch(form-govt)" },
                { label: "Name Similarity (doc-form)", key: "nameSim(doc-form)" },
                { label: "Name Similarity Match (doc-form)", key: "nameSimMatch(doc-form)" },
                { label: "Name Similarity (doc-govt)", key: "nameSim(doc-govt)" },
                { label: "Name Similarity Match (doc-govt)", key: "nameSimMatch(doc-govt)" },
                
                { label: "Gender", key: "gender" },
                
                { label: "Dob", key: "dateOfBirth" },
                { label: "Dob Similarity (form-govt)", key: "dobSim(form-govt)" },
                { label: "Dob Similarity (doc-form)", key: "dobSim(doc-form)" },
                { label: "Dob Similarity (doc-govt)", key: "dobSim(doc-govt)" },

                { label: "Yob Similarity (form-govt)", key: "yobSim(form-govt)" },
                { label: "Yob Similarity (doc-form)", key: "yobSim(doc-form)" },
                { label: "Yob Similarity (doc-govt)", key: "yobSim(doc-govt)" },

                { label: "Parent Name", key: "parentName" },
                { label: "Address", key: "address" },
                
                { label: "GC Status", key: "gcStatus" },
                { label: "Govt Check Status", key: "govtCheckStatus" },
                { label: "GC Name", key: "gcName" },
                { label: "GC Dob", key: "gcDob" },
                { label: "GC Face Similarity", key: "gcFaceSimilarity" },
                { label: "Face Similarity", key: "faceSimilarity" },
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }

        if (props.serviceName === 'cardLiveness') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },

                { label: "User ID", key: "userId" },

                { label: "Real Confidence", key: "realConfidence" },
                { label: "Fake Confidence", key: "FakeConfidence" },
                
                
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }


        if (props.serviceName === 'photocopy') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },

                { label: "User ID", key: "userId" },

                { label: "Photocopy Confidence", key: "photocopyConfidence" },
                { label: "Real Confidence", key: "realConfidence" },  
                
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }


        if (props.serviceName === 'namematch') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },

                { label: "User ID", key: "userId" },

                { label: "Similarity", key: "similarity" },
                { label: "Match", key: "match" },  
                { label: "Source", key: "source" },  
                { label: "Target", key: "target" },  
                
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }

        if (props.serviceName === 'liveness') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },

                { label: "User ID", key: "userId" },

                { label: "Probability", key: "probability" },
                { label: "Liveness Status", key: "liveness_status" },  
   
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }
        

        if (props.serviceName === 'videoKyc') {
            const headers = [
                { label: "User ID", key: "userId" },
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Mobile", key: "mobile" },
                { label: "Status", key: "status" },
   
            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }

        if (props.serviceName === 'selfie') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },
                { label: "Similarity", key: "similarity" },
                { label: "Message", key: "message" },

            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }

        if (props.serviceName === 'mask') {
            const headers = [
                { label: "Transaction Id", key: "transactionId" },
                { label: "UTC Timestamp", key: "utcTimestamp" },
                { label: "Status", key: "status" },
                { label: "Message", key: "message" },

            ];
            return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            headers={headers}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink> 
        }
            

        return <CSVLink
            id="downloadCsv"
            data={props.csvData}
            filename={`${getClientDisplayName(props.clientName)}_${getDisplayName(props.serviceName)}_${props.startDate} To ${props.endDate}.csv`}
            className="downloadButton"
        >Download CSV</CSVLink>
    } 

}

export default AsyncCsvButton;