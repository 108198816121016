import React, { Component } from "react";
import "../styles/home.scss";
import { withRouter } from "react-router-dom";

class Home extends Component {
  createUser = () => {
    this.props.history.push("/create-user");
  };

  updateUser = () => {
    this.props.history.push("/update-user");
  };

  deleteUser = () => {
    this.props.history.push("/user-active-status");
  };

  updateGC = () => {
    this.props.history.push("/update-gc");
  }

  showBilling = () => {
    this.props.history.push("/billing");
  }

  render() {
    return (
      <div className="home-container">
        <div className="tab" onClick={this.createUser}>
          Create New User
        </div>
        <div className="tab" onClick={this.updateUser}>
          Update User
        </div>
        <div className="tab" onClick={this.deleteUser}>
          Inactive/Active Users
        </div>
        <div className="tab" onClick={this.updateGC}>
          Update Official DB Backend
        </div>
        <div className="tab" onClick={this.showBilling}>
          Billing
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
