import React from 'react'
import '../../styles/billing/app.css';
import { getClientActualName, convertToIST, convertToPoperDate, lastMonthDate, customDate, 
    getLastMonthStartDate, getLastMonthStartDisplayDate, getLastMonthEndDate, getLastMonthEndDisplayDate } from "./utils"
import { TransactionCount, ServiceButtons, Header, Error, PriceComponent, DateComponent } from './Components'

class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isLoadingTransaction: false,
            error: false,
            clientName: [],
            displayClientName: [],
            selectedClientName: null,
            selectedClientDisplayName: null,
            services: [],
            selectedServiceName: null,
            startDate: "",
            endDate: "",
            price: 1,
            isSamePriceForAll: true,
            billingData: null,
            csvLoading: false,
            csvError: false,
            csvData: null,
            clientNameError: false,
            serviceNameError: false,
            transCountError: false,
            selectedDateType: lastMonthDate,
            billingDetails: null,
            invoiceNo: null,
            isLoadingInvoiceNo: false,
            isErrorInvoiceNo: false,
            additionalNotes: ""
        }
    }


    componentDidMount() {
        const obj = { headers: { "authkey": this.props.authkey } }
        fetch(`${process.env.REACT_APP_API_URL}/clientName`, obj)
            .then(async (response) => {
                if (response.status === 200) {
                    var responseData = await response.json()
                    const displayData = {}

                    const data = []


                    for (let index = 0; index < responseData["data"].length; index++) {
                        const name = responseData['data'][index].name
                        data.push(name)
                        if (name !== 'invoid' && name.slice(-6) === 'invoid') {
                            displayData[name] = name.slice(0, -6)
                            // displayData[index] = name.slice(0, -6)
                        } else {
                            displayData[name] = name
                        }
                    }


                    this.setState({
                        clientName: data,
                        displayClientName: displayData,
                        loading: false,
                        clientNameError: false,
                        invoiceNo: null,
                        isErrorInvoiceNo: false,
                        isLoadingInvoiceNo: false

                    })
                } else {
                    this.setState({
                        clientNameError: true,
                        loading: false,
                        invoiceNo: null,
                        isErrorInvoiceNo: false,
                        isLoadingInvoiceNo: false
                    })
                }
            }).catch((e) => {
                console.log(e)
                this.setState({
                    clientNameError: true,
                    loading: false,
                    invoiceNo: null,
                    isErrorInvoiceNo: false,
                    isLoadingInvoiceNo: false
                })
            })

    }

    // componentDidUpdate() {
    //     setTimeout(() => {
    //         const downloadCsvButton = document.getElementById('downloadCsv')
    //         if (downloadCsvButton) {
    //             document.getElementById('downloadCsv').click()
    //         }
    //         const invoiceButton = document.getElementsByTagName('a')[0]
    //         if (invoiceButton && invoiceButton.innerHTML === "Generate Invoice") {
    //             invoiceButton.click()
    //         }

    //     }, 500)
    // }

    render() {
        if (this.state.loading) {

            return <div style={{ width: "100%" }}>
                <Header />
                <div class="loader" />
            </div>

        } else {
            if (this.state.clientNameError) {
                return <div style={{ width: "100%" }}>
                    <Header />
                    <Error text="Error getting client name. Refresh page" />
                </div>
            }
            return <div style={{ width: "100%" }}>
                <Header />
                <div style={{ margin: "24px" }}>
                    <label for="clientName">Choose client name: </label>
                    <select name="clientName" onChange={this.onChange} defaultValue="DEFAULT" value={this.state.selectedClientDisplayName}>
                        <option value="DEFAULT" disabled>-- select an option -- </option>

                        {this.state.clientName.map((e) => {

                            e = this.state.displayClientName[e]
                            if (this.state.selectedClientDisplayName === e) {
                                return <option value={e} selected>{e}</option>;
                            } else {
                                return <option value={e}>{e}</option>;
                            }

                        })}
                    </select>
                    <p></p>
                    {/* // startDate, onDateChange, endDate, onDateChange, onLastMonthSelected, onCustomSelected, selectedDateType
 */}
                    <DateComponent
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onDateChange={this.onDateChange}
                        onLastMonthSelected={this.onLastMonthSelected}
                        onCustomSelected={this.onCustomSelected}
                        selectedDateType={this.state.selectedDateType}

                    />
                    <p></p>
                    <PriceComponent price={this.state.price} onPriceChange={this.onPriceChange} isSamePriceForAll={this.state.isSamePriceForAll} onSamePriceCheckChange={this.onSamePriceCheckChange} />
                    <p></p>
                    <ServiceButtons serviceNameError={this.state.serviceNameError} services={this.state.services} onTabButtonClick={this.onTabButtonClick} selectedServiceName={this.state.selectedServiceName} />
                    <p></p>

                    {/* invoiceNo, isLoadingInvoiceNo, isErrorInvoiceNo, invoiceOnClick  */}

                    <TransactionCount
                        isLoading={this.state.isLoadingTransaction}
                        price={this.state.price}
                        selectedClientName={this.state.selectedClientName}
                        billingData={this.state.billingData}
                        onCheckboxChange={this.onCheckboxChange}
                        selectedServiceName={this.state.selectedServiceName}
                        csvLoading={this.state.csvLoading}
                        csvError={this.state.csvError}
                        csvData={this.state.csvData}
                        csvOnClick={this.downloadCsv}
                        onBillDetailsCheckboxChange={this.onBillDetailsCheckboxChange}

                        isSamePriceChecked={this.state.isSamePriceForAll}
                        onIndividualPriceChange={this.onIndividualPriceChange}
                        onLableChange={this.onLableChange}
                        onAddExtraEntry={this.onAddExtraEntry}
                        billingDetails={this.state.billingDetails}

                        invoiceNo={this.state.invoiceNo}
                        isLoadingInvoiceNo={this.state.isLoadingInvoiceNo}
                        isErrorInvoiceNo={this.state.isErrorInvoiceNo}
                        invoiceOnClick={this.invoiceOnClick}

                        onAdditionalNotesChange={this.onAdditionalNotesChange}
                        additionalNotes={this.state.additionalNotes}

                        startDate={this.getStartDate()}
                        endDate={this.getEndDate()}

                    />
                </div>

            </div>
        }
    }

    onChange = (event) => {
        var value = getClientActualName(this.state.displayClientName, event.target.value)

        this.setState({
            loading: true,
            selectedClientName: value,
            selectedClientDisplayName: event.target.value,
            selectedServiceName: null,
            billingData: null,
            csvLoading: false,
            csvData: null,
            csvError: false,
            serviceNameError: false,
            invoiceNo: null,
            isErrorInvoiceNo: false,
            isLoadingInvoiceNo: false

        })

        var url;
        if (value !== 'invoid' && value !== 'payone') {
            url = process.env.REACT_APP_API_URL + "/services?clientName=" + value + "invoid"
        } else {
            url = process.env.REACT_APP_API_URL + "/services?clientName=" + value
        }

        url = process.env.REACT_APP_API_URL + "/services?clientName=" + value

        const obj = { headers: { "authkey": this.props.authkey } }
        fetch(url, obj)
            .then(async (response) => {
                if (response.status === 200) {
                    var data = await response.json()
                    data["name"].push('fv')
                    data["name"].push('ic')
                    this.setState({
                        loading: false,
                        services: data["name"],
                        serviceNameError: false,
                        billingDetails: data["billingDetails"],
                        invoiceNo: null,
                        isErrorInvoiceNo: false,
                        isLoadingInvoiceNo: false
                    })

                } else {
                    this.setState({
                        serviceNameError: true,
                        loading: false,
                        invoiceNo: null,
                        isErrorInvoiceNo: false,
                        isLoadingInvoiceNo: false
                    })
                }
                console.log(this.state.billingDetails)
            }).catch((e) => {
                console.log(e)
                this.setState({
                    serviceNameError: true,
                    loading: false,
                    invoiceNo: null,
                    isErrorInvoiceNo: false,
                    isLoadingInvoiceNo: false
                })
            })

    }

    onDateChange = (event) => {

        var value = event.target.value

        if (event.target.id === 'startDate') {
            this.setState({
                startDate: value,
                selectedServiceName: null,
                billingData: null,
                csvLoading: false,
                csvData: null,
                csvError: false,
                invoiceNo: null,
                isErrorInvoiceNo: false,
                isLoadingInvoiceNo: false
            })
        } else {
            this.setState({
                endDate: value,
                selectedServiceName: null,
                billingData: null,
                csvLoading: false,
                csvData: null,
                csvError: false,
                invoiceNo: null,
                isErrorInvoiceNo: false,
                isLoadingInvoiceNo: false
            })
        }
    }

    onPriceChange = (event) => {
        var value = event.target.value

        const billingData = this.state.billingData
        if (billingData) {
            for (const key in this.state.billingData) {
                billingData[key]['price'] = value
            }


        }
        this.setState({
            price: value,
            billingData: billingData,
            invoiceNo: null,
            isErrorInvoiceNo: false,
            isLoadingInvoiceNo: false
        })
    }

    getStartDate() {
        var apiStartDate = getLastMonthStartDisplayDate()

        if (this.state.selectedDateType === customDate) {
            const element = document.getElementById('startDate')
            if (element !== null) {
                const endDate = element.value
                if (endDate === "") {
                    return apiStartDate
                }
                apiStartDate = convertToPoperDate(element.valueAsNumber)
            }

        }
        return apiStartDate
    }

    getEndDate() {
        var apiEndDate = getLastMonthEndDisplayDate()

        if (this.state.selectedDateType === customDate) {
            const element = document.getElementById('endDate')
            if (element !== null) {
                const endDate = element.value
                if (endDate === "") {
                    return apiEndDate
                }
                apiEndDate = convertToPoperDate(element.valueAsNumber)
            }

        }
        return apiEndDate
    }

    onTabButtonClick = (event) => {
        var value = event.target.value

        var apiStartDate = getLastMonthStartDate()
        var apiEndDate = getLastMonthEndDate()

        if (this.state.selectedDateType === customDate) {
            const startDate = document.getElementById('startDate').value
            const endDate = document.getElementById('endDate').value
            if (startDate === "" || endDate === "") {
                return
            }
            apiStartDate = convertToIST(document.getElementById('startDate').valueAsNumber, true)
            apiEndDate = convertToIST(document.getElementById('endDate').valueAsNumber, false)
        }

        this.setState({
            isLoadingTransaction: true,
            selectedServiceName: value,
            csvLoading: false,
            csvData: null,
            csvError: false,
            transCountError: false,
            invoiceNo: null,
            isErrorInvoiceNo: false,
            isLoadingInvoiceNo: false
        })

        if (this.state.billingData != null && this.state.billingData[value]) {
            this.setState({
                isLoadingTransaction: false
            })

            return
        }


        const url = process.env.REACT_APP_API_URL + "/services_count?clientName="
            + this.state.selectedClientName
            + "&serviceName=" + value + "&startDate=" +
            apiStartDate + "&endDate=" + apiEndDate

        const obj = { headers: { "authkey": this.props.authkey } }

        fetch(url, obj)
            .then(async (response) => {
                if (response.status === 200) {
                    var data = await response.json()
                    var count = data["count"]
                    var billingData = this.state.billingData
                    if (billingData == null) {
                        billingData = {}
                    }

                    if (value === "governmentCheck") {
                        billingData[value] = {
                            count: count,
                            price: this.state.price,
                            pan: {
                                count: data['gc']['panCount'],
                                price: 1.0
                            },
                            panSupreme: {
                                count: data['gc']['panSupremeCount'],
                                price: 1.0
                            },
                            panLite: {
                                count: data['gc']['panLiteCount'],
                                price: 1.0
                            },
                            panPrime: {
                                count: data['gc']['panPrimeCount'],
                                price: 1.0
                            },
                            dl: {
                                count: data['gc']['dlCount'],
                                price: 1.0
                            },
                            voter: {
                                count: data['gc']['voterCount'],
                                price: 1.0
                            },
                            dlPrime: {
                                count: data['gc']['dlPrimeCount'],
                                price: 1.0
                            },
                            bank: {
                                count: data['gc']['bankCount'],
                                price: 1.0
                            },
                            rcLite: {
                                count: data['gc']['rcLiteCount'],
                                price: 1.0
                            },
                            rcPrime: {
                                count: data['gc']['rcPrimeCount'],
                                price: 1.0
                            },
                            gstLite: {
                                count: data['gc']['gstLiteCount'],
                                price: 1.0
                            },
                            gstAdvance: {
                                count: data['gc']['gstAdvanceCount'],
                                price: 1.0
                            }
                        }
                    } else if (value === "ic") {
                        billingData[value] = {
                            label: "IC",
                            count: count,
                            price: this.state.price,
                            aadhaarIc: {
                                count: data['ic_fv']['aadhaarCount'],
                                price: 1.0
                            },
                            panIc: {
                                count: data['ic_fv']['panCount'],
                                price: 1.0
                            },
                            dlIc: {
                                count: data['ic_fv']['dlCount'],
                                price: 1.0
                            },
                            voterIc: {
                                count: data['ic_fv']['voterCount'],
                                price: 1.0
                            },
                            rcIc: {
                                count: data['ic_fv']['rcCount'],
                                price: 1.0
                            },
                            passportIc: {
                                count: data['ic_fv']['passportCount'],
                                price: 1.0
                            },
                            chequeIc: {
                                count: data['ic_fv']['chequeCount'],
                                price: 1.0
                            },
                            otherIc: {
                                count: data['ic_fv']['otherCount'],
                                price: 1.0
                            },


                        }
                    } else if (value === "fv") {
                        billingData[value] = {
                            label: "FV",
                            count: count,
                            price: this.state.price,
                            aadhaarFv: {
                                count: data['ic_fv']['aadhaarCount'],
                                price: 1.0
                            },
                            panFv: {
                                count: data['ic_fv']['panCount'],
                                price: 1.0
                            },
                            dlFv: {
                                count: data['ic_fv']['dlCount'],
                                price: 1.0
                            },
                            voterFv: {
                                count: data['ic_fv']['voterCount'],
                                price: 1.0
                            },
                            rcFv: {
                                count: data['ic_fv']['rcCount'],
                                price: 1.0
                            },
                            passportFv: {
                                count: data['ic_fv']['passportCount'],
                                price: 1.0
                            },
                            chequeFv: {
                                count: data['ic_fv']['chequeCount'],
                                price: 1.0
                            },
                            otherFv: {
                                count: data['ic_fv']['otherCount'],
                                price: 1.0
                            },


                        }
                    } else {
                        billingData[value] = {
                            count: count,
                            price: this.state.price
                        }
                    }



                    this.setState({
                        isLoadingTransaction: false,
                        billingData: billingData,
                        transCountError: false,
                        invoiceNo: null,
                        isErrorInvoiceNo: false,
                        isLoadingInvoiceNo: false
                    })

                } else {
                    this.setState({
                        transCountError: true,
                        isLoadingTransaction: false,
                        invoiceNo: null,
                        isErrorInvoiceNo: false,
                        isLoadingInvoiceNo: false
                    })
                }
            }).catch((e) => {
                this.setState({
                    transCountError: true,
                    isLoadingTransaction: false,
                    invoiceNo: null,
                    isErrorInvoiceNo: false,
                    isLoadingInvoiceNo: false
                })
            })
    }

    onCheckboxChange = (event) => {
        var checked = event.target.checked
        var elementName = event.target.name
        if (checked) {
            var billingData = this.state.billingData

            if (this.state.selectedServiceName === 'governmentCheck' || this.state.selectedServiceName === 'ic' || this.state.selectedServiceName === 'fv') {
                billingData[this.state.selectedServiceName][elementName]["addToBill"] = true
            } else {
                billingData[this.state.selectedServiceName]["addToBill"] = true
                billingData[this.state.selectedServiceName]["addToBill"] = true
                billingData[this.state.selectedServiceName]["addToBill"] = true
            }

            this.setState({
                billingData: billingData,
                invoiceNo: null,
                isErrorInvoiceNo: false,
                isLoadingInvoiceNo: false
            })
        } else {
            const billingData = this.state.billingData
            if (this.state.selectedServiceName === 'governmentCheck' || this.state.selectedServiceName === 'ic' || this.state.selectedServiceName === 'fv') {
                billingData[this.state.selectedServiceName][elementName]["addToBill"] = false
            } else {
                billingData[this.state.selectedServiceName]["addToBill"] = false
            }
            // billingData[this.state.selectedServiceName]["addToBill"] = false
            this.setState({
                billingData: billingData,
                invoiceNo: null,
                isErrorInvoiceNo: false,
                isLoadingInvoiceNo: false
            })
        }

    }

    onBillDetailsCheckboxChange = (event) => {
        var elementName = event.target.name
        var billingData = this.state.billingData

        if (elementName === 'pan' || elementName === 'panSupreme'
            || elementName === 'dl' || elementName === 'voter'
            || elementName === 'dlPrime' || elementName === 'bank'
            || elementName === 'rcLite' || elementName === 'rcPrime'
            || elementName === 'gstLite' || elementName === 'gstAdvance'
            || elementName === 'panLite' || elementName === 'panPrime'

        ) {
            billingData['governmentCheck'][elementName]["addToBill"] = false

        } else if (elementName === 'panIc' || elementName === 'aadhaarIc'
            || elementName === 'dlIc' || elementName === 'voterIc'
            || elementName === 'rcIc' || elementName === 'chequeIc'
            || elementName === 'passportIc' || elementName === 'otherIc'
        ) {
            billingData['ic'][elementName]["addToBill"] = false

        } else if (elementName === 'panFv' || elementName === 'aadhaarFv'
            || elementName === 'dlFv' || elementName === 'voterFv'
            || elementName === 'rcFv' || elementName === 'chequeFv'
            || elementName === 'passportFv' || elementName === 'otherFv'
        ) {
            billingData['fv'][elementName]["addToBill"] = false
        }

        else {
            billingData[elementName]["addToBill"] = false
        }

        this.setState({
            billingData: billingData,
            invoiceNo: null,
            isErrorInvoiceNo: false,
            isLoadingInvoiceNo: false
        })

    }

    downloadCsv = () => {

        var apiStartDate = getLastMonthStartDate()
        var apiEndDate = getLastMonthEndDate()

        if (this.state.selectedDateType === customDate) {
            const startDate = document.getElementById('startDate').value
            const endDate = document.getElementById('endDate').value
            if (startDate === "" || endDate === "") {
                return
            }
            apiStartDate = convertToIST(document.getElementById('startDate').valueAsNumber, true)
            apiEndDate = convertToIST(document.getElementById('endDate').valueAsNumber, false)
        }

        const url = process.env.REACT_APP_API_URL + "/services_csv_data?clientName="
            + this.state.selectedClientName
            + "&serviceName=" + this.state.selectedServiceName + "&startDate=" +
            apiStartDate + "&endDate=" + apiEndDate

        this.setState({
            csvLoading: true
        })

        const obj = { headers: { "authkey": this.props.authkey } }

        fetch(url, obj)
            .then(async (response) => {
                if (response.status === 200) {
                    var data = await response.json()
                    this.setState({
                        csvLoading: false,
                        csvError: false,
                        csvData: data["transactions"]
                    }, () => {
                        setTimeout(() => {
                            const csvButton = document.getElementById('downloadCsv')
                            if (csvButton) {
                                csvButton.click()
                            }
                        }, 100)
                    })
                } else {
                    this.setState({
                        csvError: true,
                        csvLoading: false
                    })
                    alert('Error downloading CSV file. \nPlease try again')
                }
            })
            .catch((e) => {
                console.log(e)
                this.setState({
                    csvError: true,
                    csvLoading: false
                })
                alert('Error downloading CSV file. \nPlease try again')
            })
    }

    onSamePriceCheckChange = (event) => {
        var checked = event.target.checked
        if (checked) {
            this.setState({
                isSamePriceForAll: true,
                price: this.state.price,
                invoiceNo: null,
                isErrorInvoiceNo: false,
                isLoadingInvoiceNo: false
            })
        } else {
            this.setState({
                isSamePriceForAll: false,
                price: this.state.price,
                invoiceNo: null,
                isErrorInvoiceNo: false,
                isLoadingInvoiceNo: false
            })
        }

    }

    onLableChange = (event) => {
        if (this.state.selectedServiceName === 'fv') {
            const billingData = this.state.billingData
            billingData["fv"]["label"] = event.target.value

            this.setState(
                {
                    billingData: billingData,
                    invoiceNo: null,
                    isErrorInvoiceNo: false,
                    isLoadingInvoiceNo: false
                }
            )
        } else if (this.state.selectedServiceName === 'ic') {
            const billingData = this.state.billingData
            billingData["ic"]["label"] = event.target.value

            this.setState(
                {
                    billingData: billingData,
                    invoiceNo: null,
                    isErrorInvoiceNo: false,
                    isLoadingInvoiceNo: false
                }
            )
        }
    }

    onAddExtraEntry = (event) => {
        const name = document.getElementById('nameExtraField').value
        const count = Number(document.getElementById('countExtraField').value)
        const price = Number(document.getElementById('priceExtraField').value)

        if (name && name !== "" && count && count !== "" && price && price !== "") {
            const billData = this.state.billingData
            billData[name] = {
                count: count,
                price: price,
                addToBill: true,
                extra: true
            }

            this.setState({
                billingData: billData,
                invoiceNo: null,
                isErrorInvoiceNo: false,
                isLoadingInvoiceNo: false
            })

        }
    }

    onIndividualPriceChange = (event) => {
        var value = event.target.value
        var id = event.target.id

        const billingData = this.state.billingData

        if (id === "price_pan") {
            billingData["governmentCheck"]["pan"]["price"] = value

        } else if (id === "price_panSupreme") {
            billingData["governmentCheck"]["panSupreme"]["price"] = value

        } else if (id === "price_dl") {
            billingData["governmentCheck"]["dl"]["price"] = value

        } else if (id === "price_voter") {
            billingData["governmentCheck"]["voter"]["price"] = value

        } else if (id === "price_dlPrime") {
            billingData["governmentCheck"]["dlPrime"]["price"] = value

        } else if (id === "price_bank") {
            billingData["governmentCheck"]["bank"]["price"] = value

        } else if (id === "price_rcLite") {
            billingData["governmentCheck"]["rcLite"]["price"] = value

        } else if (id === "price_rcPrime") {
            billingData["governmentCheck"]["rcPrime"]["price"] = value

        } else if (id === "price_gstLite") {
            billingData["governmentCheck"]["gstLite"]["price"] = value

        } else if (id === "price_gstAdvance") {
            billingData["governmentCheck"]["gstAdvance"]["price"] = value

        } else if (id === "price_panLite") {
            billingData["governmentCheck"]["panLite"]["price"] = value

        } else if (id === "price_panPrime") {
            billingData["governmentCheck"]["panPrime"]["price"] = value
        }

        else if (id === "price_panIc") {
            billingData["ic"]["panIc"]["price"] = value
        }
        else if (id === "price_aadhaarIc") {
            billingData["ic"]["aadhaarIc"]["price"] = value
        }
        else if (id === "price_dlIc") {
            billingData["ic"]["dlIc"]["price"] = value
        }
        else if (id === "price_voterIc") {
            billingData["ic"]["voterIc"]["price"] = value
        }
        else if (id === "price_rcIc") {
            billingData["ic"]["rcIc"]["price"] = value
        }
        else if (id === "price_passportIc") {
            billingData["ic"]["passportIc"]["price"] = value
        }
        else if (id === "price_chequeIc") {
            billingData["ic"]["chequeIc"]["price"] = value
        }
        else if (id === "price_otherIc") {
            billingData["ic"]["otherIc"]["price"] = value
        }

        else if (id === "price_panFv") {
            billingData["fv"]["panFv"]["price"] = value
        }
        else if (id === "price_aadhaarFv") {
            billingData["fv"]["aadhaarFv"]["price"] = value
        }
        else if (id === "price_dlFv") {
            billingData["fv"]["dlFv"]["price"] = value
        }
        else if (id === "price_voterFv") {
            billingData["fv"]["voterFv"]["price"] = value
        }
        else if (id === "price_rcFv") {
            billingData["fv"]["rcFv"]["price"] = value
        }
        else if (id === "price_passportFv") {
            billingData["fv"]["passportFv"]["price"] = value
        }
        else if (id === "price_chequeFv") {
            billingData["fv"]["chequeFv"]["price"] = value
        }
        else if (id === "price_otherFv") {
            billingData["fv"]["otherFv"]["price"] = value
        }

        this.setState({
            billingData: billingData,
            invoiceNo: null,
            isErrorInvoiceNo: false,
            isLoadingInvoiceNo: false
        })

    }

    onLastMonthSelected = (event) => {
        this.setState({
            startDate: "",
            endDate: "",
            selectedServiceName: null,
            billingData: null,
            csvLoading: false,
            csvData: null,
            csvError: false,
            selectedDateType: lastMonthDate,
            invoiceNo: null,
            isErrorInvoiceNo: false,
            isLoadingInvoiceNo: false
        })

    }

    onCustomSelected = (event) => {
        this.setState({
            startDate: "",
            endDate: "",
            selectedServiceName: null,
            billingData: null,
            csvLoading: false,
            csvData: null,
            csvError: false,
            selectedDateType: customDate,
            invoiceNo: null,
            isErrorInvoiceNo: false,
            isLoadingInvoiceNo: false
        })

    }

    invoiceOnClick = () => {
        const url = process.env.REACT_APP_API_URL + "/invoice"
        this.setState({
            isLoadingInvoiceNo: true
        })

        const obj = { headers: { "authkey": this.props.authkey } }

        fetch(url, obj)
            .then(async (response) => {
                if (response.status === 200) {
                    var data = await response.json()
                    this.setState({
                        isLoadingInvoiceNo: false,
                        isErrorInvoiceNo: false,
                        invoiceNo: data["invoiceNumber"]
                    }, () => {
                        setTimeout(() => {
                            console.log('clicking generate invoice button')
                            const invoiceButton = document.getElementsByTagName('a')[0]
                            if (invoiceButton && invoiceButton.innerHTML === "Generate Invoice") {
                                invoiceButton.click()
                            }
                        }, 500)
                    })
                } else {
                    this.setState({
                        isLoadingInvoiceNo: false,
                        isErrorInvoiceNo: true,
                    })
                    alert('Error generating invoice. \nPlease try again')
                }
            })
            .catch((e) => {
                console.log(e)
                this.setState({
                    isLoadingInvoiceNo: false,
                    isErrorInvoiceNo: true,
                })
                alert('Error generating invoice. \nPlease try again')
            })
    }

    onAdditionalNotesChange = (event) => {
        const notes = event.target.value
        this.setState({
            additionalNotes: notes
        })
    }

}

export default Home;