import React from 'react';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { GeneratePdf, styles } from "./GeneratePdf";
import AsyncCsvButton from "./AsyncCsvButton";

import { customDate, getDisplayName, getTooltipText, getPrice } from "./utils"
import logo from "../../assets/logo_dark_img.png";


// onAddExtraEntry
// invoiceNo, isLoadingInvoiceNo, isErrorInvoiceNo, invoiceOnClick, onAdditionalNotesChange 
// additionalNotes
export function TransactionCount(props) {
    if (props.isLoading) {
        return <div>
            <p></p>
            <div class="transLoader"></div>
        </div>
    }
    if (props.billingData != null && props.billingData[props.selectedServiceName] !== undefined) {

        // isSamePriceChecked, onIndividualPriceChange, billingData, selectedServiceName, onAddToBillChange
        // fvLabel, icLabel

        return <div class="myFlexDiv">
            <div style={{ "background-color": 'white', "width": "50%" }}>
                <ServiceData
                    billingData={props.billingData}
                    price={props.price}
                    selectedServiceName={props.selectedServiceName}
                    selectedClientName={props.selectedClientName}
                    csvLoading={props.csvLoading}
                    csvError={props.csvError}
                    csvData={props.csvData}
                    csvOnClick={props.csvOnClick}
                    isSamePriceChecked={props.isSamePriceChecked}
                    onLableChange={props.onLableChange}

                    startDate={props.startDate}
                    endDate={props.endDate}
                />
                {/* // isSamePriceChecked, docTypeName, docTypeId, count, price, onIndividualPriceChange, billingData, selectedServiceName, onAddToBillChange
 */}
                <IndividualTransCount
                    billingData={props.billingData}
                    selectedServiceName={props.selectedServiceName}
                    onAddToBillChange={props.onCheckboxChange}

                    isSamePriceChecked={props.isSamePriceChecked}
                    onIndividualPriceChange={props.onIndividualPriceChange}

                />


            </div>
            <div>
                {/* invoiceNo, isLoadingInvoiceNo, isErrorInvoiceNo, invoiceOnClick, onAdditionalNotesChange.additionalNotes */}
                <BillingAndInvoiceComponent billingData={props.billingData}
                    onBillDetailsCheckboxChange={props.onBillDetailsCheckboxChange}
                    commonPrice={props.price}
                    isSamePriceChecked={props.isSamePriceChecked}
                    onAddExtraEntry={props.onAddExtraEntry}
                    billingDetails={props.billingDetails}
                    invoiceNo={props.invoiceNo}
                    isLoadingInvoiceNo={props.isLoadingInvoiceNo}
                    isErrorInvoiceNo={props.isErrorInvoiceNo}
                    invoiceOnClick={props.invoiceOnClick}
                    onAdditionalNotesChange={props.onAdditionalNotesChange}
                    additionalNotes={props.additionalNotes}
                />
            </div>
        </div>

    }
    return <p></p>
}

export function ServiceButtons(props) {
    if (props.serviceNameError) {
        return <Error text="Error, Try Again" />
    } else {
        if (props.services.length > 0)
            return <div class="tab">
                {props.services.map((e) => {
                    return <ServiceButton serviceName={e} selectedServiceName={props.selectedServiceName} onTabButtonClick={props.onTabButtonClick} />
                })}
            </div>
        return <p></p>

    }
}

export function ServiceButton(props) {

    if (props.serviceName === props.selectedServiceName) {
        return <div class="tooltip">
            <button id="tablinks" class="header-text" value={props.serviceName} onClick={props.onTabButtonClick}>{getDisplayName(props.serviceName)}</button>
            <span class="tooltiptext">{getTooltipText(props.serviceName)}</span>
        </div>
    } else {
        return <div class="tooltip">
            <button class="header-text" value={props.serviceName} onClick={props.onTabButtonClick}>{getDisplayName(props.serviceName)}</button>
            <span class="tooltiptext">{getTooltipText(props.serviceName)}</span>
        </div>
    }
}

// invoiceNo, isLoadingInvoiceNo, isErrorInvoiceNo, invoiceOnClick
export function GenerateInvoiceComponent(props) {

    if (props.billingData != null) {
        for (const key in props.billingData) {
            if (props.billingData[key]["addToBill"] === true

                || (props.billingData.governmentCheck !== undefined
                    && (props.billingData["governmentCheck"]["pan"]["addToBill"]
                        || props.billingData["governmentCheck"]["panSupreme"]["addToBill"]
                        || props.billingData["governmentCheck"]["dl"]["addToBill"]
                        || props.billingData["governmentCheck"]["dlPrime"]["addToBill"]
                        || props.billingData["governmentCheck"]["voter"]["addToBill"]
                        || props.billingData["governmentCheck"]["bank"]["addToBill"]
                        || props.billingData["governmentCheck"]["rcLite"]["addToBill"]
                        || props.billingData["governmentCheck"]["rcPrime"]["addToBill"]
                        || props.billingData["governmentCheck"]["gstLite"]["addToBill"]
                        || props.billingData["governmentCheck"]["gstAdvance"]["addToBill"]
                        || props.billingData["governmentCheck"]["panPrime"]["addToBill"]
                        || props.billingData["governmentCheck"]["panLite"]["addToBill"]))

                || (props.billingData.ic !== undefined
                    && (props.billingData["ic"]["panIc"]["addToBill"]
                        || props.billingData["ic"]["dlIc"]["addToBill"]
                        || props.billingData["ic"]["voterIc"]["addToBill"]
                        || props.billingData["ic"]["chequeIc"]["addToBill"]
                        || props.billingData["ic"]["rcIc"]["addToBill"]
                        || props.billingData["ic"]["aadhaarIc"]["addToBill"]
                        || props.billingData["ic"]["otherIc"]["addToBill"]
                        || props.billingData["ic"]["passportIc"]["addToBill"]))

                || (props.billingData.fv !== undefined
                    && (props.billingData["fv"]["panFv"]["addToBill"]
                        || props.billingData["fv"]["dlFv"]["addToBill"]
                        || props.billingData["fv"]["voterFv"]["addToBill"]
                        || props.billingData["fv"]["chequeFv"]["addToBill"]
                        || props.billingData["fv"]["rcFv"]["addToBill"]
                        || props.billingData["fv"]["aadhaarFv"]["addToBill"]
                        || props.billingData["fv"]["otherFv"]["addToBill"]
                        || props.billingData["fv"]["passportFv"]["addToBill"]))



            ) {

                if (props.isLoadingInvoiceNo) {
                    return <button style={{ "margin": "24px 16px 16px 0px", "backgroundColor": "#2e579e88" }} className="downloadButton" disabled >Generating...</button>
                }

                if (props.invoiceNo === null || props.isErrorInvoiceNo) {
                    return <button
                        style={{ "margin": "24px 16px 16px 0px", "backgroundColor": "#2e579e" }}
                        className="downloadButton" onClick={props.invoiceOnClick}>
                        Generate Invoice
                    </button>

                }


                return <div style={{ "margin": "24px 16px 16px 0px", "width": "300px" }}>
                    <PDFDownloadLink
                        document={
                            <GeneratePdf
                                billingData={props.billingData}
                                price={props.price}
                                isSamePriceChecked={props.isSamePriceChecked}
                                billingDetails={props.billingDetails}
                                invoiceNo={props.invoiceNo}
                                additionalNotes={props.additionalNotes}
                            />
                        } fileName={props.invoiceNo + ".pdf"} style={styles.button}>
                        Generate Invoice
                    </PDFDownloadLink>
                </div>
            }
        }
    }

    return <p></p>
}

export function ServiceData(props) {
    if (props.billingData && props.billingData[props.selectedServiceName]["count"] !== null) {
        const count = props.billingData[props.selectedServiceName]["count"]
        if (count > 0) {

            if (props.selectedServiceName === 'fv') {
                return <div>

                    <div>
                        <span class='myspan'>Total transactions: {count.toLocaleString()} </span>
                        <TotalCostComponent
                            selectedServiceName={props.selectedServiceName}
                            billingData={props.billingData}
                            isSamePriceChecked={props.isSamePriceChecked}
                            price={props.price}
                        />

                        {/* <span class='myspan'>Total Cost: Rs {(count * props.price).toLocaleString()}</span> */}

                        <AsyncCsvButton
                            csvLoading={props.csvLoading}
                            csvError={props.csvError}
                            csvData={props.csvData}
                            csvOnClick={props.csvOnClick}
                            clientName={props.selectedClientName}
                            serviceName={props.selectedServiceName}

                            startDate={props.startDate}
                            endDate={props.endDate}
                        />
                    </div>

                    <div>
                        <br></br>
                        <label for="fvLabel">Label: </label>
                        <input id="fvLabel" value={props.billingData.fv.label} onChange={props.onLableChange}></input>
                    </div>


                </div>
            }

            if (props.selectedServiceName === "ic") {

                return <div>

                    <div>
                        <span class='myspan'>Total transactions: {count.toLocaleString()} </span>
                        <TotalCostComponent
                            selectedServiceName={props.selectedServiceName}
                            billingData={props.billingData}
                            isSamePriceChecked={props.isSamePriceChecked}
                            price={props.price}
                        />

                        {/* <span class='myspan'>Total Cost: Rs {(count * props.price).toLocaleString()}</span> */}

                        <AsyncCsvButton
                            csvLoading={props.csvLoading}
                            csvError={props.csvError}
                            csvData={props.csvData}
                            csvOnClick={props.csvOnClick}
                            clientName={props.selectedClientName}
                            serviceName={props.selectedServiceName}

                            startDate={props.startDate}
                            endDate={props.endDate}
                        />
                    </div>

                    <div>
                        <br></br>
                        <label for="icLabel">Label: </label>
                        <input id="icLabel" value={props.billingData.ic.label} onChange={props.onLableChange}></input>
                    </div>


                </div>


            }

            return <div>

                <span class='myspan'>Total transactions: {count.toLocaleString()} </span>
                <TotalCostComponent
                    selectedServiceName={props.selectedServiceName}
                    billingData={props.billingData}
                    isSamePriceChecked={props.isSamePriceChecked}
                    price={props.price}
                />

                {/* <span class='myspan'>Total Cost: Rs {(count * props.price).toLocaleString()}</span> */}

                <AsyncCsvButton
                    csvLoading={props.csvLoading}
                    csvError={props.csvError}
                    csvData={props.csvData}
                    csvOnClick={props.csvOnClick}
                    clientName={props.selectedClientName}
                    serviceName={props.selectedServiceName}

                    startDate={props.startDate}
                    endDate={props.endDate}
                />

            </div>
        } else {
            return <div>
                <span class='myspan'>Total transactions: {count.toLocaleString()} </span>
                <TotalCostComponent
                    selectedServiceName={props.selectedServiceName}
                    billingData={props.billingData}
                    isSamePriceChecked={props.isSamePriceChecked}
                    price={props.price} />
            </div>
        }
    } else {
        return <p></p>
    }

}

export function Header(props) {
    return <div class="header">
        <div>
            <img src={logo} alt="invoid logo" class="header-image" />
            <strong class="header-text">Billing System</strong>
        </div>
    </div>
}

export function Error(props) {
    return <div class="error">
        <p>{props.text}</p>
    </div>
}


// billingData, selectedServiceName, onCheckboxChange
// isSamePriceChecked, docTypeName, docTypeId, count, price, onPriceChange, billingData, selectedServiceName, onAddToBillChange

function IndividualTransCount(props) {

    if (props.selectedServiceName === "governmentCheck" && props.billingData) {
        const panCount = props.billingData[props.selectedServiceName]['pan']['count']
        const dlCount = props.billingData[props.selectedServiceName]['dl']['count']
        const panSupremeCount = props.billingData[props.selectedServiceName]['panSupreme']['count']
        const voterCount = props.billingData[props.selectedServiceName]['voter']['count']
        const dlPrimeCount = props.billingData[props.selectedServiceName]['dlPrime']['count']
        const bankCount = props.billingData[props.selectedServiceName]['bank']['count']
        const rcLiteCount = props.billingData[props.selectedServiceName]['rcLite']['count']
        const rcPrimeCount = props.billingData[props.selectedServiceName]['rcPrime']['count']
        const gstLiteCount = props.billingData[props.selectedServiceName]['gstLite']['count']
        const gstAdvanceCount = props.billingData[props.selectedServiceName]['gstAdvance']['count']
        const panPrimeCount = props.billingData[props.selectedServiceName]['panPrime']['count']
        const panLiteCount = props.billingData[props.selectedServiceName]['panLite']['count']

        // isSamePriceChecked, docTypeName, docTypeId, count, price, onIndividualPriceChange, billingData, selectedServiceName, onAddToBillChange


        return <div class="gcDocContainer">


            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Pan"
                docTypeId="pan"
                count={panCount}
                price={props.billingData['governmentCheck']['pan']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Pan-Supreme"
                docTypeId="panSupreme"
                count={panSupremeCount}
                price={props.billingData['governmentCheck']['panSupreme']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Pan-Prime"
                docTypeId="panPrime"
                count={panPrimeCount}
                price={props.billingData['governmentCheck']['panPrime']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Pan-Lite"
                docTypeId="panLite"
                count={panLiteCount}
                price={props.billingData['governmentCheck']['panLite']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="DL"
                docTypeId="dl"
                count={dlCount}
                price={props.billingData['governmentCheck']['dl']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="DL Prime"
                docTypeId="dlPrime"
                count={dlPrimeCount}
                price={props.billingData['governmentCheck']['dlPrime']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Voter"
                docTypeId="voter"
                count={voterCount}
                price={props.billingData['governmentCheck']['voter']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />
            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Bank A/C"
                docTypeId="bank"
                count={bankCount}
                price={props.billingData['governmentCheck']['bank']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}
            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="RC Lite"
                docTypeId="rcLite"
                count={rcLiteCount}
                price={props.billingData['governmentCheck']['rcLite']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}
            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="RC Prime"
                docTypeId="rcPrime"
                count={rcPrimeCount}
                price={props.billingData['governmentCheck']['rcPrime']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}
            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="GST Lite"
                docTypeId="gstLite"
                count={gstLiteCount}
                price={props.billingData['governmentCheck']['gstLite']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}
            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="GST Advance"
                docTypeId="gstAdvance"
                count={gstAdvanceCount}
                price={props.billingData['governmentCheck']['gstAdvance']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}
            />
        </div>
    }

    if (props.selectedServiceName === "ic" && props.billingData) {
        const panCount = props.billingData[props.selectedServiceName]['panIc']['count']
        const dlCount = props.billingData[props.selectedServiceName]['dlIc']['count']
        const voterCount = props.billingData[props.selectedServiceName]['voterIc']['count']
        const aadhaarCount = props.billingData[props.selectedServiceName]['aadhaarIc']['count']
        const passportCount = props.billingData[props.selectedServiceName]['passportIc']['count']
        const rcCount = props.billingData[props.selectedServiceName]['rcIc']['count']
        const otherCount = props.billingData[props.selectedServiceName]['otherIc']['count']
        const chequeCount = props.billingData[props.selectedServiceName]['chequeIc']['count']


        // isSamePriceChecked, docTypeName, docTypeId, count, price, onIndividualPriceChange, billingData, selectedServiceName, onAddToBillChange


        return <div class="gcDocContainer">


            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Pan"
                docTypeId="panIc"
                count={panCount}
                price={props.billingData[props.selectedServiceName]['panIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="DL"
                docTypeId="dlIc"
                count={dlCount}
                price={props.billingData[props.selectedServiceName]['dlIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Aadhaar"
                docTypeId="aadhaarIc"
                count={aadhaarCount}
                price={props.billingData[props.selectedServiceName]['aadhaarIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Voter"
                docTypeId="voterIc"
                count={voterCount}
                price={props.billingData[props.selectedServiceName]['voterIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Passport"
                docTypeId="passportIc"
                count={passportCount}
                price={props.billingData[props.selectedServiceName]['passportIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="RC"
                docTypeId="rcIc"
                count={rcCount}
                price={props.billingData[props.selectedServiceName]['rcIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Cheque"
                docTypeId="chequeIc"
                count={chequeCount}
                price={props.billingData[props.selectedServiceName]['chequeIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Other"
                docTypeId="otherIc"
                count={otherCount}
                price={props.billingData[props.selectedServiceName]['otherIc']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />


        </div>
    }


    if (props.selectedServiceName === "fv" && props.billingData) {
        const panCount = props.billingData[props.selectedServiceName]['panFv']['count']
        const dlCount = props.billingData[props.selectedServiceName]['dlFv']['count']
        const voterCount = props.billingData[props.selectedServiceName]['voterFv']['count']
        const aadhaarCount = props.billingData[props.selectedServiceName]['aadhaarFv']['count']
        const passportCount = props.billingData[props.selectedServiceName]['passportFv']['count']
        const rcCount = props.billingData[props.selectedServiceName]['rcFv']['count']
        const otherCount = props.billingData[props.selectedServiceName]['otherFv']['count']
        const chequeCount = props.billingData[props.selectedServiceName]['chequeFv']['count']


        // isSamePriceChecked, docTypeName, docTypeId, count, price, onIndividualPriceChange, billingData, selectedServiceName, onAddToBillChange


        return <div class="gcDocContainer">


            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Pan"
                docTypeId="panFv"
                count={panCount}
                price={props.billingData[props.selectedServiceName]['panFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="DL"
                docTypeId="dlFv"
                count={dlCount}
                price={props.billingData[props.selectedServiceName]['dlFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Aadhaar"
                docTypeId="aadhaarFv"
                count={aadhaarCount}
                price={props.billingData[props.selectedServiceName]['aadhaarFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Voter"
                docTypeId="voterFv"
                count={voterCount}
                price={props.billingData[props.selectedServiceName]['voterFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Passport"
                docTypeId="passportFv"
                count={passportCount}
                price={props.billingData[props.selectedServiceName]['passportFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="RC"
                docTypeId="rcFv"
                count={rcCount}
                price={props.billingData[props.selectedServiceName]['rcFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Cheque"
                docTypeId="chequeFv"
                count={chequeCount}
                price={props.billingData[props.selectedServiceName]['chequeFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />

            <IndividualDocTypeCountComponent
                isSamePriceChecked={props.isSamePriceChecked}
                docTypeName="Other"
                docTypeId="otherFv"
                count={otherCount}
                price={props.billingData[props.selectedServiceName]['otherFv']['price']}
                onIndividualPriceChange={props.onIndividualPriceChange}
                billingData={props.billingData}
                selectedServiceName={props.selectedServiceName}
                onAddToBillChange={props.onAddToBillChange}

            />


        </div>
    }



    return <AddToBill
        billingData={props.billingData}
        selectedServiceName={props.selectedServiceName}
        onCheckboxChange={props.onAddToBillChange}
    />
}



// billingData, selectedServiceName, doctype, onCheckboxChange
function AddToBill(props) {
    if (props.selectedServiceName === null || props.billingData === null) {
        return <span></span>
    }

    if (props.selectedServiceName === "governmentCheck") {
        if (props.billingData['governmentCheck'][props.docType]['addToBill']) {
            return <span>
                <input type="checkbox" name={props.docType}
                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </span>
        } else {
            return <span>
                <input type="checkbox" name={props.docType} id="addBillCheckbox" onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </span>
        }

    } else if (props.selectedServiceName === "ic") {
        if (props.billingData['ic'][props.docType]['addToBill']) {
            return <span>
                <input type="checkbox" name={props.docType}
                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </span>
        } else {
            return <span>
                <input type="checkbox" name={props.docType} id="addBillCheckbox" onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </span>
        }

    } else if (props.selectedServiceName === "fv") {
        if (props.billingData['fv'][props.docType]['addToBill']) {
            return <span>
                <input type="checkbox" name={props.docType}
                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </span>
        } else {
            return <span>
                <input type="checkbox" name={props.docType} id="addBillCheckbox" onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </span>
        }
    }

    else {
        if (props.billingData[props.selectedServiceName]["addToBill"]) {
            return <p>
                <input type="checkbox" id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </p>
        } else {
            return <p>
                <input type="checkbox" id="addBillCheckbox" onChange={props.onCheckboxChange}></input>
                <label for="addBillCheckbox"> Add to bill</label>
            </p>
        }



    }
}

// onAddExtraEntry , commonPrice, isSamePriceChecked,additionalNotes, onAdditionalNotesChange
function BillingDetails(props) {
    if (props.billingData === null) {
        return <p></p>
    }

    const mainDoc = []
    const doc = []
    mainDoc.push(<div class="header-text" style={{ "padding": "16px 16px 16px 0px", "color": "black", "margin-left": "0px" }}><span>
        Billing Details:</span></div>)

    for (const key in props.billingData) {
        if (props.billingData[key]["addToBill"] === true

            || (props.billingData.governmentCheck !== undefined
                && (props.billingData["governmentCheck"]["pan"]["addToBill"]
                    || props.billingData["governmentCheck"]["panSupreme"]["addToBill"]
                    || props.billingData["governmentCheck"]["dl"]["addToBill"]
                    || props.billingData["governmentCheck"]["dlPrime"]["addToBill"]
                    || props.billingData["governmentCheck"]["voter"]["addToBill"]
                    || props.billingData["governmentCheck"]["bank"]["addToBill"]
                    || props.billingData["governmentCheck"]["rcLite"]["addToBill"]
                    || props.billingData["governmentCheck"]["rcPrime"]["addToBill"]
                    || props.billingData["governmentCheck"]["gstLite"]["addToBill"]
                    || props.billingData["governmentCheck"]["gstAdvance"]["addToBill"]
                    || props.billingData["governmentCheck"]["panPrime"]["addToBill"]
                    || props.billingData["governmentCheck"]["panLite"]["addToBill"]))

            || (props.billingData.ic !== undefined
                && (props.billingData["ic"]["panIc"]["addToBill"]
                    || props.billingData["ic"]["dlIc"]["addToBill"]
                    || props.billingData["ic"]["voterIc"]["addToBill"]
                    || props.billingData["ic"]["chequeIc"]["addToBill"]
                    || props.billingData["ic"]["rcIc"]["addToBill"]
                    || props.billingData["ic"]["aadhaarIc"]["addToBill"]
                    || props.billingData["ic"]["otherIc"]["addToBill"]
                    || props.billingData["ic"]["passportIc"]["addToBill"]))

            || (props.billingData.fv !== undefined
                && (props.billingData["fv"]["panFv"]["addToBill"]
                    || props.billingData["fv"]["dlFv"]["addToBill"]
                    || props.billingData["fv"]["voterFv"]["addToBill"]
                    || props.billingData["fv"]["chequeFv"]["addToBill"]
                    || props.billingData["fv"]["rcFv"]["addToBill"]
                    || props.billingData["fv"]["aadhaarFv"]["addToBill"]
                    || props.billingData["fv"]["otherFv"]["addToBill"]
                    || props.billingData["fv"]["passportFv"]["addToBill"]))



        ) {

            if (key === 'governmentCheck') {

                if (props.billingData[key]['pan']['addToBill']) {
                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="pan"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(PAN)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['pan']['count']}</td>
                        <td>{getPrice(props.billingData[key]['pan'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['pan'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['pan']['count']}</td>

                    </tr>
                    doc.push(d)
                }

                if (props.billingData[key]['panSupreme']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="panSupreme"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(PAN Supreme)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['panSupreme']['count']}</td>
                        <td>{getPrice(props.billingData[key]['panSupreme'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['panSupreme'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['panSupreme']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['panPrime']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="panPrime"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(PAN Prime)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['panPrime']['count']}</td>
                        <td>{getPrice(props.billingData[key]['panPrime'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['panPrime'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['panPrime']['count']}</td>

                    </tr>


                    doc.push(d)
                }

                if (props.billingData[key]['panLite']['addToBill']) {
                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="panLite"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(PAN Lite)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['panLite']['count']}</td>
                        <td>{getPrice(props.billingData[key]['panLite'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['panLite'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['panLite']['count']}</td>

                    </tr>
                    doc.push(d)
                }

                if (props.billingData[key]['dl']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="dl"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(DL)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['dl']['count']}</td>
                        <td>{getPrice(props.billingData[key]['dl'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['dl'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['dl']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['dlPrime']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="dlPrime"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(DL Prime)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['dlPrime']['count']}</td>
                        <td>{getPrice(props.billingData[key]['dlPrime'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['dlPrime'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['dlPrime']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['voter']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="voter"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(Voter)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['voter']['count']}</td>
                        <td>{getPrice(props.billingData[key]['voter'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['voter'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['voter']['count']}</td>

                    </tr>


                    doc.push(d)
                }

                if (props.billingData[key]['bank']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="bank"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(Bank)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['bank']['count']}</td>
                        <td>{getPrice(props.billingData[key]['bank'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['bank'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['bank']['count']}</td>

                    </tr>


                    doc.push(d)
                }

                if (props.billingData[key]['rcLite']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="rcLite"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(RC Lite)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['rcLite']['count']}</td>
                        <td>{getPrice(props.billingData[key]['rcLite'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['rcLite'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['rcLite']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['rcPrime']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="rcPrime"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(RC Prime)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['rcPrime']['count']}</td>
                        <td>{getPrice(props.billingData[key]['rcPrime'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['rcPrime'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['rcPrime']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['gstLite']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="gstLite"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(GST Lite)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['gstLite']['count']}</td>
                        <td>{getPrice(props.billingData[key]['gstLite'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['gstLite'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['gstLite']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['gstAdvance']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="gstAdvance"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">Official DB Check(GST Advance)</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['gstAdvance']['count']}</td>
                        <td>{getPrice(props.billingData[key]['gstAdvance'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['gstAdvance'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['gstAdvance']['count']}</td>

                    </tr>

                    doc.push(d)
                }

            } else if (key === 'ic') {

                if (props.billingData[key]['panIc']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="panIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (PAN)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['panIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['panIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['panIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['panIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['dlIc']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="dlIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (DL)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['dlIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['dlIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['dlIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['dlIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['aadhaarIc']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="aadhaarIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Aadhaar)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['aadhaarIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['aadhaarIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['aadhaarIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['aadhaarIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['voterIc']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="voterIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Voter)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['voterIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['voterIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['voterIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['voterIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['rcIc']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="rcIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (RC)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['rcIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['rcIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['rcIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['rcIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['passportIc']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="passportIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Passport)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['passportIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['passportIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['passportIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['passportIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['chequeIc']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="chequeIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Cheque)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['chequeIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['chequeIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['chequeIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['chequeIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['otherIc']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="otherIc"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Other)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['otherIc']['count']}</td>
                        <td>{getPrice(props.billingData[key]['otherIc'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['otherIc'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['otherIc']['count']}</td>

                    </tr>

                    doc.push(d)
                }
            } else if (key === 'fv') {

                if (props.billingData[key]['panFv']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="panFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (PAN)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['panFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['panFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['panFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['panFv']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['dlFv']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="dlFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (DL)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['dlFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['dlFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['dlFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['dlFv']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['aadhaarFv']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="aadhaarFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Aadhaar)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['aadhaarFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['aadhaarFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['aadhaarFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['aadhaarFv']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['voterFv']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="voterFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Voter)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['voterFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['voterFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['voterFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['voterFv']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['rcFv']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="rcFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (RC)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['rcFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['rcFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['rcFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['rcFv']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['passportFv']['addToBill']) {


                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="passportFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Passport)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['passportFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['passportFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['passportFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['passportFv']['count']}</td>

                    </tr>

                    doc.push(d)
                }

                if (props.billingData[key]['chequeFv']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="chequeFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Cheque)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['chequeFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['chequeFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['chequeFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['chequeFv']['count']}</td>

                    </tr>
                    doc.push(d)
                }

                if (props.billingData[key]['otherFv']['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name="otherFv"
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{props.billingData[key]["label"] + " (Other)"}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['otherFv']['count']}</td>
                        <td>{getPrice(props.billingData[key]['otherFv'], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key]['otherFv'], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['otherFv']['count']}</td>

                    </tr>

                    doc.push(d)
                }


            } else {
                if (props.billingData[key]['addToBill']) {

                    const d = <tr>
                        <td>
                            <li class="myLi">
                                <input type="checkbox" name={key}
                                    id="addBillCheckbox" checked onChange={props.onCheckboxChange}></input>
                                <label for="addBillCheckbox">{getDisplayName(key)}</label>
                            </li>
                        </td>
                        <td>{props.billingData[key]['count']}</td>
                        <td>{getPrice(props.billingData[key], props.commonPrice, props.isSamePriceChecked)}</td>
                        <td>{getPrice(props.billingData[key], props.commonPrice, props.isSamePriceChecked)
                            * props.billingData[key]['count']}</td>

                    </tr>

                    doc.push(d)
                }
            }
        }
    }


    if (doc.length > 0) {

        const extraFields = <tr>
            <td>
                <input size="20" id="nameExtraField" placeholder="Name"></input>
            </td>
            <td>
                <input size="5" id="countExtraField" placeholder="Count"></input>
            </td>
            <td>
                <input size="4" id="priceExtraField" placeholder="Price"></input>
            </td>
            <td><button onClick={props.onAddExtraEntry}>Add</button></td>
        </tr>

        doc.push(extraFields)

        const table = <table>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>Unit</th>
                    <th>Price</th>
                    <th>Cost</th>
                </tr>
                <BillingTable docs={doc} />
            </tbody>

        </table>


        mainDoc.push(table)
        const notes = <div class="header-text" style={{ "padding": "16px 16px 0px 0px", "color": "black", "margin-left": "0px" }}>
            <input
                placeholder="Additional Notes"
                value={props.additionalNotes}
                onChange={props.onAdditionalNotesChange}>
            </input>
        </div>
        mainDoc.push(notes)
        return mainDoc
    } else {
        return null
    }
}

function BillingTable(props) {
    return props.docs
}

// onAddExtraEntry, commonPrice, isSamePriceChecked
//invoiceNo, isLoadingInvoiceNo, isErrorInvoiceNo, invoiceOnClick, onAdditionalNotesChange 
// additionalNotes
function BillingAndInvoiceComponent(props) {
    if (props.billingData === null) {
        return <p></p>
    }

    for (const key in props.billingData) {
        if (props.billingData[key]["addToBill"] === true

            || (props.billingData.governmentCheck !== undefined
                && (props.billingData["governmentCheck"]["pan"]["addToBill"]
                    || props.billingData["governmentCheck"]["panSupreme"]["addToBill"]
                    || props.billingData["governmentCheck"]["dl"]["addToBill"]
                    || props.billingData["governmentCheck"]["dlPrime"]["addToBill"]
                    || props.billingData["governmentCheck"]["voter"]["addToBill"]
                    || props.billingData["governmentCheck"]["bank"]["addToBill"]
                    || props.billingData["governmentCheck"]["rcLite"]["addToBill"]
                    || props.billingData["governmentCheck"]["rcPrime"]["addToBill"]
                    || props.billingData["governmentCheck"]["gstLite"]["addToBill"]
                    || props.billingData["governmentCheck"]["gstAdvance"]["addToBill"]
                    || props.billingData["governmentCheck"]["panPrime"]["addToBill"]
                    || props.billingData["governmentCheck"]["panLite"]["addToBill"]))

            || (props.billingData.ic !== undefined
                && (props.billingData["ic"]["panIc"]["addToBill"]
                    || props.billingData["ic"]["dlIc"]["addToBill"]
                    || props.billingData["ic"]["voterIc"]["addToBill"]
                    || props.billingData["ic"]["chequeIc"]["addToBill"]
                    || props.billingData["ic"]["rcIc"]["addToBill"]
                    || props.billingData["ic"]["aadhaarIc"]["addToBill"]
                    || props.billingData["ic"]["otherIc"]["addToBill"]
                    || props.billingData["ic"]["passportIc"]["addToBill"]))

            || (props.billingData.fv !== undefined
                && (props.billingData["fv"]["panFv"]["addToBill"]
                    || props.billingData["fv"]["dlFv"]["addToBill"]
                    || props.billingData["fv"]["voterFv"]["addToBill"]
                    || props.billingData["fv"]["chequeFv"]["addToBill"]
                    || props.billingData["fv"]["rcFv"]["addToBill"]
                    || props.billingData["fv"]["aadhaarFv"]["addToBill"]
                    || props.billingData["fv"]["otherFv"]["addToBill"]
                    || props.billingData["fv"]["passportFv"]["addToBill"]))



        ) {


            // return <div class="myspan" style={{ "width": "300px" }}>
            return <div class="myspan" style={{ "width": "100%" }}>
                {/* // onAddExtraEntry , commonPrice, isSamePriceChecked , onAdditionalNotesChange, additionalNotes*/}
                <BillingDetails
                    billingData={props.billingData}
                    onCheckboxChange={props.onBillDetailsCheckboxChange}
                    onAddExtraEntry={props.onAddExtraEntry}
                    commonPrice={props.commonPrice}
                    isSamePriceChecked={props.isSamePriceChecked}
                    onAdditionalNotesChange={props.onAdditionalNotesChange}
                    additionalNotes={props.additionalNotes}
                />
                {/* // invoiceNo, isLoadingInvoiceNo, isErrorInvoiceNo, invoiceOnClick, additionalNotes */}
                <GenerateInvoiceComponent
                    billingData={props.billingData}
                    price={props.commonPrice}
                    isSamePriceChecked={props.isSamePriceChecked}
                    billingDetails={props.billingDetails}
                    invoiceNo={props.invoiceNo}
                    isLoadingInvoiceNo={props.isLoadingInvoiceNo}
                    isErrorInvoiceNo={props.isErrorInvoiceNo}
                    invoiceOnClick={props.invoiceOnClick}
                    additionalNotes={props.additionalNotes}
                />
            </div>

        }
    }

    return <p></p>

}


// price, onPriceChange, isSamePriceForAll, onSamePriceCheckChange
export function PriceComponent(props) {

    if (props.isSamePriceForAll) {
        return <div>
            <label for="price">Price/request: </label>
            <input type="number" size="5" id="price" max="2" min="0" step="0.1" value={props.price} onChange={props.onPriceChange} />
            <input style={{ margin: "0px 0px 0px 24px" }} type="checkbox" name="priceCheckBox"
                id="priceCheckBox" checked onChange={props.onSamePriceCheckChange}></input>
            <label for="priceCheckBox"> Same price for all Official DB check/FV/IC doctype</label>
        </div>
    } else {
        return <div>
            <label for="price">Price/request: </label>
            <input type="number" id="price" max="2" min="0" step="0.1" value={props.price} onChange={props.onPriceChange} />
            <input style={{ margin: "0px 0px 0px 24px" }} type="checkbox" name="priceCheckBox"
                id="priceCheckBox" onChange={props.onSamePriceCheckChange}></input>
            <label for="priceCheckBox"> Same price for all Official DB check/FV/IC doctype</label>
        </div>
    }

}

// isSamePriceChecked, docTypeName, docTypeId, count, price, onPriceChange, billingData, selectedServiceName, onAddToBillChange
function IndividualDocTypeCountComponent(props) {

    if (props.isSamePriceChecked) {

        return <div class="gcSingleDoc">
            <div style={{ "fontSize": "28px", "height": "50px" }}>
                <p style={{}}>{props.count}</p>
            </div>

            <div style={{ "text-transform": "uppercase", "font-size": "14px" }}>
                <span>{props.docTypeName}</span><br />
            </div>
            <p></p>
            <div class="myFloatLeft">
                <AddToBill
                    billingData={props.billingData}
                    selectedServiceName={props.selectedServiceName}
                    onCheckboxChange={props.onAddToBillChange}
                    docType={props.docTypeId}
                />
            </div>
        </div>

    } else {
        return <div class="gcSingleDoc">
            <div style={{ "fontSize": "28px", "height": "50px" }}>
                <p style={{}}>{props.count}</p>
            </div>
            <div style={{ "text-transform": "uppercase", "font-size": "14px" }}>
                <span>{props.docTypeName}</span><br />
            </div>
            <p></p>
            <div class="myFloatLeft">

                <label for="panPrice">Price/request: </label>
                <input style={{ width: '50px' }} type="number"
                    id={"price_" + props.docTypeId} max="2" min="0" step="0.1"
                    value={props.billingData[props.selectedServiceName][props.docTypeId]["price"]}
                    onChange={props.onIndividualPriceChange} />
                <br></br>
                <br></br>
                <AddToBill
                    billingData={props.billingData}
                    selectedServiceName={props.selectedServiceName}
                    onCheckboxChange={props.onAddToBillChange}
                    docType={props.docTypeId}
                />
            </div>
        </div>
    }

}

//selectedServiceName, billingData
function TotalCostComponent(props) {
    if (props.isSamePriceChecked) {
        return <span class='myspan'>Total Cost: Rs {(props.billingData[props.selectedServiceName]["count"] * props.price).toLocaleString()}</span>
    }
    if (props.selectedServiceName === "governmentCheck") {

        var data = props.billingData["governmentCheck"]["pan"]
        const panCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["panSupreme"]
        const panSupremeCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["dl"]
        const dlCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["dlPrime"]
        const dlPrimeCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["voter"]
        const voterCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["bank"]
        const bankCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["rcLite"]
        const rcLiteCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["rcPrime"]
        const rcPrimeCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["gstLite"]
        const gstLiteCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["gstAdvance"]
        const gstAdvanceCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["panPrime"]
        const panPrimeCost = data["price"] * data["count"]

        data = props.billingData["governmentCheck"]["panLite"]
        const panLiteCost = data["price"] * data["count"]

        return <span class='myspan'>Total Cost: Rs {(panCost + panSupremeCost + dlCost + dlPrimeCost
            + voterCost + bankCost + rcLiteCost + rcPrimeCost + gstLiteCost + gstAdvanceCost
            + panPrimeCost + panLiteCost).toLocaleString()}</span>
    }

    if (props.selectedServiceName === "ic") {

        var data = props.billingData["ic"]["panIc"]
        const panCost = data["price"] * data["count"]

        data = props.billingData["ic"]["dlIc"]
        const dlCost = data["price"] * data["count"]

        data = props.billingData["ic"]["aadhaarIc"]
        const aadhaarCost = data["price"] * data["count"]

        data = props.billingData["ic"]["voterIc"]
        const voterCost = data["price"] * data["count"]

        data = props.billingData["ic"]["chequeIc"]
        const chequeCost = data["price"] * data["count"]

        data = props.billingData["ic"]["rcIc"]
        const rcCost = data["price"] * data["count"]

        data = props.billingData["ic"]["passportIc"]
        const passportCost = data["price"] * data["count"]

        data = props.billingData["ic"]["otherIc"]
        const otherCost = data["price"] * data["count"]



        return <span class='myspan'>Total Cost: Rs {(panCost + aadhaarCost + dlCost + passportCost
            + voterCost + chequeCost + rcCost + otherCost).toLocaleString()}</span>
    }

    if (props.selectedServiceName === "fv") {

        var data = props.billingData["fv"]["panFv"]
        const panCost = data["price"] * data["count"]

        data = props.billingData["fv"]["dlFv"]
        const dlCost = data["price"] * data["count"]

        data = props.billingData["fv"]["aadhaarFv"]
        const aadhaarCost = data["price"] * data["count"]

        data = props.billingData["fv"]["voterFv"]
        const voterCost = data["price"] * data["count"]

        data = props.billingData["fv"]["chequeFv"]
        const chequeCost = data["price"] * data["count"]

        data = props.billingData["fv"]["rcFv"]
        const rcCost = data["price"] * data["count"]

        data = props.billingData["fv"]["passportFv"]
        const passportCost = data["price"] * data["count"]

        data = props.billingData["fv"]["otherFv"]
        const otherCost = data["price"] * data["count"]



        return <span class='myspan'>Total Cost: Rs {(panCost + aadhaarCost + dlCost + passportCost
            + voterCost + chequeCost + rcCost + otherCost).toLocaleString()}</span>
    }

    return <span class='myspan'>Total Cost: Rs {(props.billingData[props.selectedServiceName]["count"] * props.price).toLocaleString()}</span>



}

// startDate, onDateChange, endDate, onDateChange, onLastMonthSelected, onCustomSelected, selectedDateType
export function DateComponent(props) {


    // if (props.serviceName === props.selectedServiceName) {
    //     return <div class="tooltip">
    //         <button id="tablinks" class="header-text" value={props.serviceName} onClick={props.onTabButtonClick}>{getDisplayName(props.serviceName)}</button>
    //         <span class="tooltiptext">{getTooltipText(props.serviceName)}</span>
    //     </div>
    // } else {
    //     return <div class="tooltip">
    //         <button class="header-text" value={props.serviceName} onClick={props.onTabButtonClick}>{getDisplayName(props.serviceName)}</button>
    //         <span class="tooltiptext">{getTooltipText(props.serviceName)}</span>
    //     </div>
    // }


    console.log(props.selectedDateType)

    if (props.selectedDateType === customDate) {
        return <div class="tab" style={{ display: "table" }}>

            <button onClick={props.onLastMonthSelected} >Last Month</button>
            <button onClick={props.onCustomSelected} id="tablinks" >Custom</button>
            <div class="myFlexDiv">
                <label class="dateLabel" for="startDate">Start Date: </label>
                <input type="date" id="startDate" value={props.startDate} onChange={props.onDateChange}></input>
                <label class="dateLabel" for="endDate">End Date: </label>
                <input type="date" id="endDate" value={props.endDate} onChange={props.onDateChange}></input>
            </div>
        </div>
    } else {
        return <div class="tab" style={{ display: "table" }}>

            <button onClick={props.onLastMonthSelected} id="tablinks">Last Month</button>
            <button onClick={props.onCustomSelected}  >Custom</button>
        </div>
    }


}