import React, { Component } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import axios from "axios";
import logo from "../assets/invoid-dark-transparent.png";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progess: {
    position: "absolute",
  },
});

class Login extends Component {
  constructor() {
    super();
    this.state = {
      otpValidated: false,
      username: "",
      password: "",
      errors: {},
      loading: false,
      otp: "",
      otpSent: false,
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const { errors } = this.state.errors;
    if (name === "username") {
      const regex = /^[0-9a-zA-Z@.!#$%&'*+-/=?^_`{|}~]*$/;
      this.setState({ errors: { ...errors, username: true } });
      if (regex.test(value))
        this.setState({
          [name]: value,
          errors: { ...errors, username: false },
        });
    } else if (name === "mobile") {
      const regex = /^[0-9]{0,10}$/;
      if (regex.test(value))
        this.setState({ [name]: value, errors: { ...errors, mobile: false } });
    } else if (name === "otp") {
      const regex = /^[0-9]{0,6}$/;
      if (regex.test(value))
        this.setState({ [name]: value, errors: { ...errors, otp: false } });
    } else {
      this.setState({ [name]: value });
    }
  };

  onSubmit = async (e) => {
    const { username, password } = this.state;
    e.preventDefault();
    this.setState({ loading: true });
    const user = {
      username: username,
      password: password,
    };
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/login`, user)
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.status === 401) {
            return alert(res.data.message);
          } else if (
            res &&
            res.data &&
            res.data.services &&
            res.data.services.includes("super-admin")
          ) {
            this.props.pushLogin(res.data.authkey);
            return this.props.history.push("/home");
          } else {
            if (res.data && res.data.services) {
              return alert("Your account is not a superuser");
            }
          }
        })
        .catch((err) => alert(err));
      this.setState({ loading: false });
    } catch (e) {
      alert(e);
      this.setState({ loading: false });
    }
  };

  sendOtp = async (e) => {
    e.preventDefault();
    try {
      const { mobile, errors } = this.state;
      if (!mobile || mobile.length !== 10) {
        this.setState({ errors: { ...errors, mobile: true } });
        return;
      }
      this.setState({ loading: true });
      let data = new FormData();
      data.append("mobile", mobile);
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-otp`,
        data
      );
      if (res && res.data && res.data.message === "otp sent!") {
        this.setState({ otpSent: true });
      } else if (res && res.data && res.data.message) {
        alert(res.data.message);
      } else {
        alert("Could not send otp");
      }
      this.setState({ loading: false });
    } catch (err) {
      alert(err.message);
      this.setState({ loading: false });
    }
  };

  verifyOtp = async (e) => {
    e.preventDefault();
    try {
      const { mobile, errors, otp } = this.state;
      if (!otp) {
        this.setState({ errors: { ...errors, otp: true } });
        return;
      }
      this.setState({ loading: true });
      let data = new FormData();
      data.append("mobile", mobile);
      data.append("otp", otp);
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp`,
        data
      );
      if (res && res.data && res.data.message === "success") {
        this.setState({ otpValidated: true });
      } else if (res && res.data && res.data.message === "fail") {
        alert("Incorrect OTP");
      } else {
        alert("OTP could not be validated");
      }
      this.setState({ loading: false });
    } catch (err) {
      alert(err.message);
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, loading, otp, mobile, otpValidated, otpSent } = this.state;
    return (
      <div className="login-container">
        <img className="invoid-logo" src={logo} alt="invoid-logo" />
        <CssBaseline />
        {loading ? (
          <CircularProgress size={150} style={{ margin: "0 auto" }} />
        ) : (
          <div className={(classes.paper, "form-container")}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            {otpValidated ? (
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  helperText={errors.username}
                  error={errors.usernmae ? true : false}
                  value={this.state.username || ""}
                  onChange={this.onChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  helperText={errors.password}
                  error={errors.password ? true : false}
                  value={this.state.password || ""}
                  onChange={this.onChange}
                />
                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="nav-button submit"
                  onClick={this.onSubmit}
                  disabled={
                    loading ||
                    !this.state.username ||
                    !this.state.password ||
                    errors.username ||
                    errors.password
                  }
                >
                  Sign In
                  {loading && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
              </form>
            ) : (
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Mobile"
                  name="mobile"
                  autoFocus
                  disabled={otpSent}
                  helperText={errors.mobile}
                  error={errors.mobile ? true : false}
                  value={mobile || ""}
                  onChange={otpSent ? null : this.onChange}
                />
                {otpSent && (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="otp"
                    label="OTP"
                    id="otp"
                    helperText={errors.otp}
                    error={errors.otp ? true : false}
                    value={otp || ""}
                    onChange={this.onChange}
                  />
                )}
                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="nav-button submit"
                  onClick={otpSent ? this.verifyOtp : this.sendOtp}
                  disabled={
                    loading ||
                    (otpSent && !otp) ||
                    !mobile ||
                    errors.mobile ||
                    errors.otp
                  }
                >
                  {otpSent ? "Verify OTP" : "Send OTP"}
                </Button>
              </form>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Login));
