import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.scss";
import Login from "./components/Login";
import Home from "./components/Home";
import NoMatch from "./components/NoMatch";
import NewUser from "./components/NewUser";
import UpdateUser from "./components/UpdateUser";
import UpdateGC from "./components/UpdateGC";
import Billing from "./components/billing/app";
import DeleteUser from "./components/DeteUser"

import { useHistory } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: false,
    };
  }

  pushLogin = (user) => {
    this.setState({ currentUser: user });
  };

  logout = () => {
    this.setState({ currentUser: false });
    const history = useHistory();
    history.push("/login");
  };

  render() {
    const { currentUser } = this.state;
    return (
      <div className="App">
        <Router>
          <Switch>
            {currentUser && (
              <>
              <Route exact path="/update-gc" render={(props) => <UpdateGC {...props} authkey={currentUser} />} />
              <Route exact path="/create-user" render={(props) => <NewUser {...props} adminAuthKey={currentUser} />}  />
              <Route exact path="/update-user" render={(props) => <UpdateUser {...props} authkey={currentUser} />} />
              <Route exact path="/user-active-status" render={(props) => <DeleteUser {...props} authkey={currentUser} />} />
              <Route exact path="/billing" render={(props) => <Billing {...props} authkey={currentUser} />} />
              <Route exact path="/home" component={Home} />
              </>
            )}
            <Route exact path="/login">
              {!currentUser ? (
                <Login pushLogin={this.pushLogin} />
              ) : (
                <Redirect to="/home" />
              )}
            </Route>
            <Route path="*">
              {!currentUser ? <NoMatch /> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
