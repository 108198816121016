import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
// import { TextField, Button, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core';
import "../styles/updateUser.scss";
import {
    TextField,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Input,
    CircularProgress,
    RadioGroup,
    Radio
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import DateFnsAdapter from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


class UpdateUser extends Component {
    state = {
        users: [],
        userDetails: {},
        selectedUser: null,
        updateData: {},
        updateLoading: false,
        selfieMatchDetails: {
            enabled: false,
            limit: 0
        },
        emailOtpDetails: {
            enabled: false,
            limit: 0,
            sender: ""
        },
        sendEmailDetails: {
            enabled: false,
            limit: 0,
            sender: ""
        },
        digilockerDetails: {
            enabled: false,
            displayName: "",
            webhook: '',
            docTypes: [],
            accountType: "",
            primaryColor: "",
            secondaryColor: "",
            digilockerPassword: ''
        },
        upiDetails: {
            enabled: false,
            limit: 0,
        },
        bankStatementDetails: {
            enabled: false,
            limit: 0,
        },
        smsOtpDetails: {
            enabled: false,
            limit: 0,
            templates: []
        },
        digilockerImage: "",
        loading: true,
        deactivate: "-1",
        serviceDialog: {
            openGovtCheckDialog: false,
            openEmailOtpDialog: false,
            openSelfieMatchDialog: false,
            openDigilockerDialog: false,
            openSendEmailDialog: false,
            openUpiDetailDialog: false,
            openBankStatementDialog: false,
            openSmsOtpDialog: false
        },
        openUpdateDoneDialog: false,
        selectedDeactiveDate: Date.now()
    }

    componentDidMount() {
        this.fetchUserList();
    }

    fetchUserList = async () => {
        try {
            const { authkey } = this.props;
            let res = await axios.post(`${process.env.REACT_APP_API_URL}/clientName`, {}, { headers: { authkey } });
            if (res && res.data && res.data.data) {
                this.setState({ users: res.data.data });
            } else if (res && res.data && res.data.message) {
                alert(res.data.message);
            } else alert('Something Went Wrong');
        } catch (e) {
            alert(e.message)
        }
        this.setState({
            loading: false
        });
    }

    changeImage = (e) => {
        this.setState({ digilockerImage: e.target.files[0] });
    };

    handleDialogSubmit = (service) => {
        const { sendEmailDetails, digilockerDetails, upiDetails, bankStatementDetails, emailOtpDetails, 
            selfieMatchDetails, serviceDialog, digilockerImage, smsOtpDetails } = this.state

        if (service === "digilocker") {
            const { displayName, webhook, docTypes, accountType, primaryColor, secondaryColor } = digilockerDetails;
            if (accountType === 'prod') {
                if (displayName !== "" && webhook !== "" && docTypes.length !== 0 && accountType !== "" && primaryColor !== "" && secondaryColor !== "") {
                    this.setState({
                        digilockerDetails: { ...digilockerDetails, enabled: true },
                        serviceDialog: { ...serviceDialog, openDigilockerDialog: false },
                    })
                }
            } else if(accountType === 'dev') {
                if (displayName !== "" && docTypes.length !== 0 && accountType !== "" && primaryColor !== "" && secondaryColor !== "") {
                    this.setState({
                        digilockerDetails: { ...digilockerDetails, enabled: true },
                        serviceDialog: { ...serviceDialog, openDigilockerDialog: false },
                    })
                }
            } else return
        }
        if (service === "sendEmailDetails") {
            if ((parseInt(sendEmailDetails.limit) !== 0 && sendEmailDetails.limit !== "") || sendEmailDetails.sender !== "") {
                this.setState({
                    sendEmailDetails: { ...sendEmailDetails, enabled: true },
                    serviceDialog: { ...serviceDialog, openSendEmailDialog: false },
                })
            }
        }
        if (service === "emailOtp") {
            if (parseInt(emailOtpDetails.limit) !== 0 || emailOtpDetails.sender !== "") {
                this.setState({
                    emailOtpDetails: { ...emailOtpDetails, enabled: true },
                    serviceDialog: { ...serviceDialog, openEmailOtpDialog: false },
                })
            }
        }
        if (service === "selfieMatch") {
            if (parseInt(selfieMatchDetails.limit) !== 0) {
                this.setState({
                    selfieMatchDetails: { ...selfieMatchDetails, enabled: true },
                    serviceDialog: { ...serviceDialog, openSelfieMatchDialog: false },
                })
            }
        }
        if (service === "upiDetails") {
            if (parseInt(upiDetails.limit) !== 0) {
                this.setState({
                    upiDetails: { ...upiDetails, enabled: true },
                    serviceDialog: { ...serviceDialog, openUpiDetailDialog: false },
                })
            }
        }
        if (service === "bankStatement") {
            if (parseInt(bankStatementDetails.limit) !== 0) {
                this.setState({
                    bankStatementDetails: { ...bankStatementDetails, enabled: true },
                    serviceDialog: { ...serviceDialog, openBankStatementDialog: false },
                })
            }
        }
        if (service === "smsOtp") {
            if (parseInt(smsOtpDetails.limit) !== 0) {
                this.setState({
                    smsOtpDetails: { ...smsOtpDetails, enabled: true },
                    serviceDialog: { ...serviceDialog, openSmsOtpDialog: false },
                })
            }
        }
    }

    handleDialog = (service) => {
        if (service === "digilocker") this.setState({ serviceDialog: { ...this.state.serviceDialog, openDigilockerDialog: false } })
        if (service === "sendEmailDetails") this.setState({ serviceDialog: { ...this.state.serviceDialog, openSendEmailDialog: false } })
        if (service === "govtCheck") this.setState({ serviceDialog: { ...this.state.serviceDialog, openGovtCheckDialog: false } })
        if (service === "emailOtp") this.setState({ serviceDialog: { ...this.state.serviceDialog, openEmailOtpDialog: false } })
        if (service === "selfieMatch") this.setState({ serviceDialog: { ...this.state.serviceDialog, openSelfieMatchDialog: false } })
        if (service === "upiDetails") this.setState({ serviceDialog: { ...this.state.serviceDialog, openUpiDetailDialog: false } })
        if (service === "bankStatement") this.setState({ serviceDialog: { ...this.state.serviceDialog, openBankStatementDialog: false } })
        if (service === "smsOtp") this.setState({ serviceDialog: { ...this.state.serviceDialog, openSmsOtpDialog: false } })
        else if (service === "updateDone") {
            this.setState({
                openUpdateDoneDialog: false,
                selectedUser: null,
                users: [],
                digilockerDetails: {
                    enabled: false,
                    webhook: '',
                    name: "",
                    displayName: "",
                    docTypes: [],
                    accountType: "",
                    primaryColor: "",
                    secondaryColor: "",
                    digilockerPassword: ""
                },
                loading: true,
                digilockerImage: "",
            });
            this.fetchUserList();
        }
    };

    onDigilockerAccountTypeChange = (event) => {
        this.setState({
            digilockerDetails: {
                ...this.state.digilockerDetails,
                accountType: event.target.value
            }
        })
    }

    onDigilockerDocChange = (event) => {
        const digilockerDoctypes = this.state.digilockerDetails.docTypes
        if (event.target.name === "digilockerAadhaar") {
            if (digilockerDoctypes.indexOf("aadhaar") !== -1) {
                let aadhaarIndex = digilockerDoctypes.indexOf("aadhaar");
                digilockerDoctypes.splice(aadhaarIndex, 1);
            } else {
                digilockerDoctypes.push("aadhaar");
            }
        } else if (event.target.name === "digilockerPan") {
            if (digilockerDoctypes.indexOf("pan") !== -1) {
                let panIndex = digilockerDoctypes.indexOf("pan");
                digilockerDoctypes.splice(panIndex, 1);
            } else {
                digilockerDoctypes.push("pan");
            }
        } else if (event.target.name === "digilockerDl") {
            if (digilockerDoctypes.indexOf("dl") !== -1) {
                let dlIndex = digilockerDoctypes.indexOf("dl");
                digilockerDoctypes.splice(dlIndex, 1);
            } else {
                digilockerDoctypes.push("dl");
            }
        }
        this.setState({
            digilockerDetails: {
                ...this.state.digilockerDetails,
                docTypes: digilockerDoctypes
            }
        });
    }

    changeUser = async (option) => {
        this.setState({
            updateData: {},
            updateLoading: false,
            selfieMatchDetails: {
                enabled: false,
                limit: 0
            },
            emailOtpDetails: {
                enabled: false,
                limit: 0,
                sender: ""
            },
            sendEmailDetails: {
                enabled: false,
                limit: 0,
                sender: ""
            },
            digilockerDetails: {
                enabled: false,
                webhook: '',
                name: "",
                displayName: "",
                docTypes: [],
                accountType: "",
                primaryColor: "",
                secondaryColor: "",
                digilockerPassword: ""
            },
            upiDetails: {
                enabled: false,
                limit: 0,
            },
            bankStatementDetails: {
                enabled: false,
                limit: 0,
            },
            digilockerImage: "",
            loading: true,
            deactivate: "-1",
        })
        const selectedUser = option.value
        var selfieEnabled = false
        var selfieLimit = 0

        var emailOtpEnabled = false
        var emailOtpLimit = 0
        var emailOtpSender = ""

        var sendEmailEnabled = false
        var sendEmailLimit = 0
        var sendEmailSender = ""

        var smsOtpEnabled = false
        var smsOtpLimit = 0
        var smsOtpTemplate = []

        this.setState({
            loading: true
        })

        try {
            const { authkey } = this.props;
            // console.log(this.state.users[selectedUser].authkey)
            let res = await axios.post(`${process.env.REACT_APP_API_URL}/clientDetails`, {}, { headers: { "authkey": authkey, "clientauthkey": this.state.users[selectedUser].authkey, "clientname": this.state.users[selectedUser].name } });

            if (res && res.data && !res.data.message) {

                const details = res.data
                // console.log(details)

                if (!!details.selfieMatch) {
                    selfieEnabled = true
                    selfieLimit = details.selfieMatch.limit
                }

                if (!!details.emailOtpDetails) {
                    emailOtpEnabled = true
                    emailOtpLimit = details.emailOtpDetails.count
                    emailOtpSender = details.emailOtpDetails.sender
                }

                if (!!details.sendEmailDetails) {
                    sendEmailEnabled = true
                    sendEmailLimit = details.sendEmailDetails.count
                    sendEmailSender = details.sendEmailDetails.sender
                }

                if (!!details.smsOtpDetails) {
                    smsOtpEnabled = true
                    smsOtpLimit = details.smsOtpDetails.count
                    smsOtpTemplate = details.smsOtpDetails.template
                }

                const selfieMatchDetails = {
                    enabled: selfieEnabled,
                    limit: selfieLimit
                }

                const emailOtpDetails = {
                    enabled: emailOtpEnabled,
                    limit: emailOtpLimit,
                    sender: emailOtpSender
                }

                const sendEmailDetails = {
                    enabled: sendEmailEnabled,
                    limit: sendEmailLimit,
                    sender: sendEmailSender
                }

                const smsOtpDetails = {
                    enabled: smsOtpEnabled,
                    limit: smsOtpLimit,
                    templates: smsOtpTemplate
                }

                if (details.authkey.includes("deactivate")) {
                    this.setState({
                        deactivate: "1",
                    })
                } else {
                    this.setState({
                        deactivate: "0",
                    })
                }

                if (details.deactivateTime !== null) {
                    const dateFns = new DateFnsAdapter();
                    console.log(dateFns.date(details.deactivateTime))
                    this.setState({
                        deactivate: "2",
                        selectedDeactiveDate: dateFns.date(details.deactivateTime).getTime()
                    })
                } else {
                    this.setState({
                        selectedDeactiveDate: Date.now()
                    })
                }

                this.setState({
                    userDetails: details,
                    selectedUser: selectedUser,
                    updateData: {},
                    selfieMatchDetails: selfieMatchDetails,
                    emailOtpDetails: emailOtpDetails,
                    sendEmailDetails: sendEmailDetails,
                    smsOtpDetails: smsOtpDetails,
                })
                if (details.digilocker !== null) {
                    this.setState({
                        digilockerDetails: {
                            enabled: true,
                            docTypes: details.digilocker.docTypes,
                            displayName: details.digilocker.displayName,
                            primaryColor: details.digilocker.primaryColor,
                            secondaryColor: details.digilocker.secondaryColor,
                            webhook: details.digilocker.webhook,
                            accountType: details.digilocker.accountType,
                            digilockerPassword: details.digilocker.digilockerPassword
                        }
                    })
                }
                if (details.upiDetails !== null) {
                    this.setState({
                        upiDetails: {
                            enabled: true,
                            limit: details.upiDetails.count,
                        }
                    })
                }
                if (details.bankStatement !== null) {
                    this.setState({
                        bankStatementDetails: {
                            enabled: true,
                            limit: details.bankStatement.count,
                        }
                    })
                }

            } else if (res && res.data && res.data.message) {
                alert(res.data.message);
            } else alert('Something Went Wrong');
            this.setState({
                loading: false
            });
        } catch (e) {
            console.log(e)
            this.setState({
                loading: false
            });
            alert(e.message)
        }

    }

    changeLimit = e => {
        const { name, value } = e.target;
        const regex = /^[0-9]*$/;
        if (regex.test(value))
            this.setState({ updateData: { ...this.state.updateData, [name]: value } });
    }

    updateLimits = async () => {
        const { updateData, users, selectedUser } = this.state;
        const { authkey } = this.props;
        try {
            await this.setState({ updateLoading: true });

            let data = { '$set': {} };
            (Object.keys(updateData)).forEach(doctype => {
                data['$set'][`config.govtCheck.${doctype}.limit`] = updateData[doctype];
            });


            const digilockerData = this.state.digilockerDetails

            if (digilockerData.enabled === true) {
                if (digilockerData.digilockerPassword === null || digilockerData.digilockerPassword === "") {
                    digilockerData.digilockerPassword = this.generateDigilockerPassword()
                    this.setState({
                        digilockerDetails: digilockerData
                    })
                }
            }

            let name = users[selectedUser].name;
            let selfieMatch = JSON.stringify(this.state.selfieMatchDetails)
            let emailOtp = JSON.stringify(this.state.emailOtpDetails)
            let sendEmail = JSON.stringify(this.state.sendEmailDetails)
            let upiDetails = JSON.stringify(this.state.upiDetails)
            let bankStatement = JSON.stringify(this.state.bankStatementDetails)
            let digilocker = JSON.stringify(digilockerData)
            let smsOtp = JSON.stringify(this.state.smsOtpDetails)
            let payload = new FormData();


            let deactivate = this.state.deactivate
            if (users[selectedUser].authkey.includes("deactivate")) {
                if (deactivate === "1" || deactivate === "2") {
                    deactivate = "-1"
                }
            } else {
                // if (deactivate === "0") {
                //     deactivate = "-1"
                // }
            }

            if (deactivate === "2") {
                payload.append("deactivateDate", this.state.selectedDeactiveDate)
            }

            payload.append('deactivate', deactivate);
            payload.append("data", JSON.stringify(data));
            payload.append("name", name);
            payload.append("selfieMatch", selfieMatch);
            payload.append("emailOtp", emailOtp);
            payload.append("sendEmail", sendEmail);
            payload.append("digilocker", digilocker);
            payload.append("upiDetails", upiDetails);
            payload.append("bankStatement", bankStatement);
            payload.append("digilockerImage", this.state.digilockerImage)
            payload.append("smsOtp", smsOtp)
            let res = await axios.post(`${process.env.REACT_APP_API_URL}/update-user`, payload, { headers: { 'authkey': authkey, 'clientauthkey': users[selectedUser].authkey } });
            if (res && res.data && res.data.message === "updated!") {

                this.setState({
                    openUpdateDoneDialog: true
                })
            } else if (res && res.data && res.data.message) {
                alert(res.data.message);
            } else {
                alert('Something Went Wrong');
            }
        } catch (e) {
            alert(e.message);
        }
        this.setState({
            updateLoading: false,
        });

    }

    handleCheckboxChange = (event) => {
        if (event.target.name === "selfieMatch") {
            if (!this.state.selfieMatchDetails.enabled) {
                this.setState({
                    serviceDialog: {
                        ...this.state.serviceDialog,
                        openSelfieMatchDialog: true,
                    }
                })
            } else {
                this.setState({
                    selfieMatchDetails: {
                        ...this.state.selfieMatchDetails,
                        enabled: event.target.checked,
                    },
                });
            }
        } else if (event.target.name === "emailOtp") {
            if (!this.state.emailOtpDetails.enabled) {
                this.setState({
                    serviceDialog: {
                        ...this.state.serviceDialog,
                        openEmailOtpDialog: true,
                    }
                })
            } else {
                this.setState({
                    emailOtpDetails: {
                        ...this.state.emailOtpDetails,
                        enabled: event.target.checked,
                    },
                });
            }
        } else if (event.target.name === "sendEmail") {
            if (!this.state.sendEmailDetails.enabled) {
                this.setState({
                    serviceDialog: {
                        ...this.state.serviceDialog,
                        openSendEmailDialog: true,
                    }
                })
            } else {
                this.setState({
                    sendEmailDetails: {
                        ...this.state.sendEmailDetails,
                        enabled: event.target.checked,
                    },
                });
            }
        } else if (event.target.name === "digilocker") {
            if (!this.state.digilockerDetails.enabled) {
                this.setState({
                    serviceDialog: {
                        ...this.state.serviceDialog,
                        openDigilockerDialog: true,
                    }
                })
            } else {
                this.setState({
                    digilockerDetails: {
                        ...this.state.digilockerDetails,
                        enabled: event.target.checked,
                    },
                });
            }
        } else if (event.target.name === "upiDetails") {
            if (!this.state.upiDetails.enabled) {
                this.setState({
                    serviceDialog: {
                        ...this.state.serviceDialog,
                        openUpiDetailDialog: true,
                    }
                })
            } else {
                this.setState({
                    upiDetails: {
                        ...this.state.upiDetails,
                        enabled: event.target.checked,
                    },
                });
            }
        } else if (event.target.name === "bankStatement") {
            if (!this.state.bankStatementDetails.enabled) {
                this.setState({
                    serviceDialog: {
                        ...this.state.serviceDialog,
                        openBankStatementDialog: true,
                    }
                })
            } else {
                this.setState({
                    bankStatementDetails: {
                        ...this.state.bankStatementDetails,
                        enabled: event.target.checked,
                    },
                });
            }

        }   else if (event.target.name === "smsOtp") {
                if (!this.state.smsOtpDetails.enabled) {
                    this.setState({
                        serviceDialog: {
                            ...this.state.serviceDialog,
                            openSmsOtpDialog: true,
                        }
                    })
                } else {
                    this.setState({
                        smsOtpDetails: {
                            ...this.state.smsOtpDetails,
                            enabled: event.target.checked,
                        },
                    });
                }    

        } else if (event.target.name === "deactivate") {
            if (this.state.deactivate === "1") {
                this.setState({
                    deactivate: "0",
                })
            } else {
                this.setState({
                    deactivate: "1",
                })
            }
        } else if (event.target.name === "deactivateLater") {
            if (this.state.deactivate === "2") {
                this.setState({
                    deactivate: "0",
                })
            } else {
                this.setState({
                    deactivate: "2",
                })
            }
        }
    };

    onDateChanged = (value) => {
        console.log(value)
        let date = new Date(value)
        this.setState({
            selectedDeactiveDate: date.getTime()
        })
    }

    render() {
        const { users, loading, updateData, selectedUser, updateLoading, selfieMatchDetails, sendEmailDetails, emailOtpDetails,
            digilockerDetails, digilockerImage, serviceDialog, upiDetails, bankStatementDetails, userDetails, deactivate, 
            openUpdateDoneDialog, smsOtpDetails, openSmsOtpDialog } = this.state;
        return (
            <>
                {loading ? <CircularProgress style={{ color: '#3066F0' }} size={60} /> :
                    <div className='update-user-container'>

                        {openUpdateDoneDialog &&
                            <Dialog
                                open={openUpdateDoneDialog ? true : false}
                                onClose={() => this.handleDialog("updateDone")}>
                                <DialogTitle>Alert</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>Update successful!</DialogContentText>
                                    {digilockerDetails && digilockerDetails.enabled && digilockerDetails.digilockerPassword !== "" && (
                                        <DialogContentText>Digilocker Password : {digilockerDetails.digilockerPassword}</DialogContentText>
                                    )}
                                </DialogContent>
                            </Dialog>

                        }

                        <Select value={selectedUser !== null ? { label: users[selectedUser].name, value: selectedUser } : null} onChange={this.changeUser} options={users.map((user, idx) => ({ label: user.name, value: idx }))} />
                        {selectedUser !== null && (
                            <>
                                <div style={{ margin: "0px 0px 0px 24px" }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={deactivate === "1"} name="deactivate" onChange={this.handleCheckboxChange} />}
                                        label="Deactivate"
                                    />
                                </div>
                                <div style={{ margin: "0px 0px 0px 24px" }}>

                                    <FormControlLabel
                                        control={<Checkbox checked={deactivate === "2"} name="deactivateLater" onChange={this.handleCheckboxChange} />}
                                        label="Deactivate (Set Time)"
                                    />

                                    {deactivate === "2" &&
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker value={this.state.selectedDeactiveDate} onChange={this.onDateChanged} />
                                        </MuiPickersUtilsProvider>}
                                </div>
                            </>
                        )}
                        {
                            selectedUser !== null && !users[selectedUser].authkey.includes("deactivate") && (

                                <>
                                    <p>Authkey: {userDetails.authkey}</p>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <p style={{ margin: "0px 0px 0px 24px" }}>Government Check</p>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    serviceDialog: {
                                                        ...serviceDialog,
                                                        openGovtCheckDialog: true,
                                                    }
                                                })
                                            }
                                            color="primary"
                                        >
                                            <EditIcon />
                                        </Button>
                                    </div>
                                    {
                                        serviceDialog.openGovtCheckDialog && (
                                            <>
                                                <Dialog
                                                    open={serviceDialog.openGovtCheckDialog ? true : false}
                                                    onClose={() => this.handleDialog("govtCheck")}
                                                >
                                                    <div
                                                        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", padding: "1rem" }}
                                                    >
                                                        {Object.keys(userDetails.govtCheck).map((doctype, idx) => (
                                                            <DialogContent>
                                                                <TextField
                                                                    key={idx}
                                                                    classes={{ root: "limit-input" }}
                                                                    variant="outlined"
                                                                    label={`${doctype} limit`}
                                                                    name={doctype}
                                                                    type="number"
                                                                    autoFocus
                                                                    value={updateData[doctype] || userDetails.govtCheck[doctype].limit}
                                                                    onChange={this.changeLimit}
                                                                />
                                                            </DialogContent>
                                                        ))}
                                                    </div>
                                                </Dialog>
                                            </>
                                        )
                                    }

                                    {/* // Selfie Match ------------------------------------------ */}

                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ margin: "0px 0px 0px 24px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={selfieMatchDetails.enabled} name="selfieMatch" onChange={this.handleCheckboxChange} />}
                                                label="Selfie Match"
                                            />
                                        </div>
                                        {selfieMatchDetails.enabled &&
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        serviceDialog: {
                                                            ...serviceDialog,
                                                            openSelfieMatchDialog: true,
                                                        }
                                                    })
                                                }
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>}
                                    </div>
                                    {serviceDialog.openSelfieMatchDialog &&
                                        <>
                                            <Dialog
                                                open={serviceDialog.openSelfieMatchDialog ? true : false}
                                                onClose={() => this.handleDialog("selfieMatch")}
                                            >
                                                <DialogTitle>Selfie Match Details:</DialogTitle>
                                                <DialogContent>
                                                    <TextField style={{ margin: "0px 0px 0px 24px" }}
                                                        classes={{ root: "limit-input" }}
                                                        variant="outlined"
                                                        required
                                                        value={selfieMatchDetails.limit}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                selfieMatchDetails: {
                                                                    ...this.state.selfieMatchDetails,
                                                                    limit: e.target.value
                                                                },
                                                            });
                                                        }}
                                                        type="number"
                                                        label="Limit" />

                                                </DialogContent>
                                                <DialogActions>
                                                    {/* <Button
                                                    onClick={() => this.handleDialog("selfieMatch")}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button> */}
                                                    <Button
                                                        onClick={() => this.handleDialogSubmit("selfieMatch")}
                                                        color="primary"
                                                    >
                                                        Submit
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                    }

                                    {/* // email OTP Details ------------------------ */}

                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ margin: "0px 0px 0px 24px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={emailOtpDetails.enabled} name="emailOtp" onChange={this.handleCheckboxChange} />}
                                                label="Email Otp"
                                            />
                                        </div>
                                        {emailOtpDetails.enabled &&
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        serviceDialog: {
                                                            ...serviceDialog,
                                                            openEmailOtpDialog: true,
                                                        }
                                                    })
                                                }
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>}
                                    </div>
                                    {
                                        serviceDialog.openEmailOtpDialog &&
                                        <>
                                            <Dialog
                                                open={serviceDialog.openEmailOtpDialog ? true : false}
                                                onClose={() => this.handleDialog("emailOtp")}
                                            >
                                                <DialogTitle>Email OTP Details:</DialogTitle>
                                                <DialogContent>
                                                    <TextField style={{ margin: "0px 0px 0px 24px" }}
                                                        classes={{ root: "limit-input" }}
                                                        variant="outlined"
                                                        required
                                                        value={emailOtpDetails.limit}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                emailOtpDetails: {
                                                                    ...this.state.emailOtpDetails,
                                                                    limit: e.target.value
                                                                },
                                                            });
                                                        }}
                                                        type="number"
                                                        label="Limit" />
                                                </DialogContent>
                                                <DialogContent>
                                                    <TextField style={{ margin: "0px 0px 16px 24px" }}
                                                        classes={{ root: "limit-input" }}
                                                        variant="outlined"
                                                        required
                                                        onChange={(e) => {
                                                            this.setState({
                                                                emailOtpDetails: {
                                                                    ...this.state.emailOtpDetails,
                                                                    sender: e.target.value
                                                                },
                                                            });
                                                        }}
                                                        value={emailOtpDetails.sender}
                                                        label="Sender Email" />
                                                </DialogContent>
                                                <DialogActions>
                                                    {/* <Button
                                                    onClick={() => this.handleDialog("emailOtp")}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button> */}
                                                    <Button
                                                        onClick={() => this.handleDialogSubmit("emailOtp")}
                                                        color="primary"
                                                    >
                                                        Submit
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                    }

                                    {/* //Send Email Details ------------------------------------ */}

                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ margin: "0px 0px 0px 24px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={sendEmailDetails.enabled} name="sendEmail" onChange={this.handleCheckboxChange} />}
                                                label="Send Email"
                                            />
                                        </div>
                                        {sendEmailDetails.enabled &&
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        serviceDialog: {
                                                            ...serviceDialog,
                                                            openSendEmailDialog: true,
                                                        }
                                                    })
                                                }
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>}
                                    </div>
                                    {
                                        serviceDialog.openSendEmailDialog &&
                                        <>
                                            <Dialog
                                                open={serviceDialog.openSendEmailDialog ? true : false}
                                                onClose={() => this.handleDialog("sendEmailDetails")}
                                            >
                                                <DialogTitle>sendEmail Details:</DialogTitle>
                                                <DialogContent>
                                                    <TextField style={{ margin: "0px 0px 0px 24px" }}
                                                        classes={{ root: "limit-input" }}
                                                        variant="outlined"
                                                        required
                                                        value={sendEmailDetails.limit}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                sendEmailDetails: {
                                                                    ...this.state.sendEmailDetails,
                                                                    limit: e.target.value
                                                                },
                                                            });
                                                        }}
                                                        type="number"
                                                        label="Limit" />
                                                </DialogContent>
                                                <DialogContent>
                                                    <TextField style={{ margin: "0px 0px 16px 24px" }}
                                                        classes={{ root: "limit-input" }}
                                                        variant="outlined"
                                                        required
                                                        onChange={(e) => {
                                                            this.setState({
                                                                sendEmailDetails: {
                                                                    ...this.state.sendEmailDetails,
                                                                    sender: e.target.value
                                                                },
                                                            });
                                                        }}
                                                        value={sendEmailDetails.sender}
                                                        label="Sender Email" />
                                                </ DialogContent>
                                                <DialogActions>
                                                    {/* <Button
                                                    onClick={() => this.handleDialog("sendEmailDetails")}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button> */}
                                                    <Button
                                                        onClick={() => this.handleDialogSubmit("sendEmailDetails")}
                                                        color="primary"
                                                    >
                                                        Submit
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                    }

                                    {/* // Digilocker Dialog ---------------------------------- */}

                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ margin: "0px 0px 0px 24px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={digilockerDetails.enabled} name="digilocker" onChange={this.handleCheckboxChange} />}
                                                label="Digilocker"
                                            />
                                        </div>
                                        {digilockerDetails.enabled &&
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        serviceDialog: {
                                                            ...serviceDialog,
                                                            openDigilockerDialog: true,
                                                        }
                                                    })
                                                }
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>}
                                    </div>
                                    {serviceDialog.openDigilockerDialog && (
                                        <Dialog
                                            open={serviceDialog.openDigilockerDialog ? true : false}
                                            onClose={() => this.handleDialog("digilocker")}
                                        >
                                            <DialogTitle>Digilocker Details:</DialogTitle>
                                            <DialogContent>
                                                <Input
                                                    type="file"
                                                    onChange={this.changeImage}
                                                    accept="image/*"
                                                />
                                            </DialogContent>
                                            <DialogContent>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    label="Primary Color"
                                                    value={digilockerDetails.primaryColor || ""}
                                                    onChange={(event) => this.setState({
                                                        digilockerDetails: {
                                                            ...this.state.digilockerDetails,
                                                            primaryColor: event.target.value,
                                                        }
                                                    })}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    label="Secondary Color"
                                                    value={digilockerDetails.secondaryColor || ""}
                                                    onChange={(event) => this.setState({
                                                        digilockerDetails: {
                                                            ...this.state.digilockerDetails,
                                                            secondaryColor: event.target.value,
                                                        }
                                                    })}
                                                />

                                            </DialogContent>
                                            <DialogContent>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Doc Type</FormLabel>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={digilockerDetails.docTypes.indexOf("aadhaar") >= 0} onChange={this.onDigilockerDocChange} name="digilockerAadhaar" />
                                                            }
                                                            label="Aadhaar"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                // <Checkbox checked={fv} onChange={this.handleChange} name="fv" />
                                                                <Checkbox checked={digilockerDetails.docTypes.indexOf("pan") >= 0} onChange={this.onDigilockerDocChange} name="digilockerPan" />
                                                            }
                                                            label="Pan"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                // <Checkbox checked={fv} onChange={this.handleChange} name="fv" />
                                                                <Checkbox checked={digilockerDetails.docTypes.indexOf("dl") >= 0} onChange={this.onDigilockerDocChange} name="digilockerDl" />
                                                            }
                                                            label="DL"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </DialogContent>


                                            <DialogContent>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Account Type</FormLabel>
                                                    <RadioGroup row name="accountType" value={digilockerDetails.accountType} onChange={this.onDigilockerAccountTypeChange}>
                                                        <FormControlLabel
                                                            value="dev"
                                                            control={
                                                                <Radio />
                                                            }
                                                            label="Development"
                                                        />
                                                        <FormControlLabel
                                                            value="prod"
                                                            control={
                                                                <Radio />
                                                            }
                                                            label="Production"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </DialogContent>


                                                <DialogContent>
                                                    <TextField
                                                        variant="outlined"
                                                        required={this.state.digilockerDetails.accountType === "prod"}
                                                        label="Webhook"
                                                        value={this.state.digilockerDetails.webhook}
                                                        onChange={(e) => this.setState({ digilockerDetails: { ...this.state.digilockerDetails, webhook: e.target.value } })}
                                                    />

                                                </DialogContent>
                                            <DialogContent>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    label="Display Name"
                                                    value={this.state.digilockerDetails.displayName}
                                                    onChange={(e) => this.setState({ digilockerDetails: { ...this.state.digilockerDetails, displayName: e.target.value } })}
                                                />
                                            </DialogContent>

                                            <DialogActions>
                                                {/* <Button
                                                    onClick={() => this.handleDialog("digilocker")}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button> */}
                                                <Button
                                                    onClick={() => this.handleDialogSubmit("digilocker")}
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}

                                    {/* // --------------------------- UPI DETAILS ------------------------------- */}
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ margin: "0px 0px 0px 24px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={upiDetails.enabled} name="upiDetails" onChange={this.handleCheckboxChange} />}
                                                label="UPI details"
                                            />
                                        </div>
                                        {upiDetails.enabled &&
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        serviceDialog: {
                                                            ...serviceDialog,
                                                            openUpiDetailDialog: true,
                                                        }
                                                    })
                                                }
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>}
                                    </div>
                                    {serviceDialog.openUpiDetailDialog && (
                                        <Dialog
                                            open={serviceDialog.openUpiDetailDialog ? true : false}
                                            onClose={() => this.handleDialog("upiDetails")}
                                        >
                                            <DialogTitle>UPI Details:</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    type="number"
                                                    label="Limit"
                                                    value={upiDetails.limit || ""}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            upiDetails: { ...upiDetails, limit: e.target.value }
                                                        })

                                                    }}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => this.handleDialogSubmit("upiDetails")}
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}

                                    {/* //  B A N K    S T A T E M E N T    D E T A I L S --------------------------------------------- */}
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ margin: "0px 0px 0px 24px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={bankStatementDetails.enabled} name="bankStatement" onChange={this.handleCheckboxChange} />}
                                                label="Bank Statement"
                                            />
                                        </div>
                                        {bankStatementDetails.enabled &&
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        serviceDialog: {
                                                            ...serviceDialog,
                                                            openBankStatementDialog: true,
                                                        }
                                                    })
                                                }
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>}
                                    </div>
                                    {serviceDialog.openBankStatementDialog && (
                                        <Dialog
                                            open={serviceDialog.openBankStatementDialog ? true : false}
                                            onClose={() => this.handleDialog("bankStatement")}
                                        >
                                            <DialogTitle>Bank Statement Details:</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    type="number"
                                                    label="Limit"
                                                    value={bankStatementDetails.limit || ""}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            bankStatementDetails: { ...bankStatementDetails, limit: e.target.value }
                                                        })

                                                    }}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => this.handleDialogSubmit("bankStatement")}
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}














                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ margin: "0px 0px 0px 24px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={smsOtpDetails.enabled} name="smsOtp" onChange={this.handleCheckboxChange} />}
                                                label="Sms Otp"
                                            />
                                        </div>
                                        {smsOtpDetails.enabled &&
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        serviceDialog: {
                                                            ...serviceDialog,
                                                            openSmsOtpDialog: true,
                                                        }
                                                    })
                                                }
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>}
                                    </div>
                                    {serviceDialog.openSmsOtpDialog && (
                                        <Dialog
                                        open={this.state.serviceDialog.openSmsOtpDialog ? true : false}
                                        onClose={() => this.handleDialog("smsOtp")}
                                        fullWidth={true}
                                        maxWidth={'lg'}
                                    >
                                        <DialogTitle>Sms Otp:</DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                variant="outlined"
                                                required
                                                type="number"
                                                label="Limit"
                                                value={this.state.smsOtpDetails.limit || ""}
                                                onChange={(e) => {
                                                    this.setState({
                                                        smsOtpDetails: { ...this.state.smsOtpDetails, limit: e.target.value }
                                                    })

                                                }}
                                            />
                                        </DialogContent>

                                        <DialogContent>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Template Id</TableCell>
                                                            <TableCell >Template Info</TableCell>
                                                            <TableCell >Template Message</TableCell>
                                                            <TableCell >Sms Sender code</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.smsOtpDetails.templates.map((template) => (
                                                            <TableRow key={template.templateId}>
                                                                <TableCell component="th" scope="row">
                                                                    {template.templateId}
                                                                </TableCell>
                                                                <TableCell >{template.templateInfo}</TableCell>
                                                                <TableCell >{template.templateMessage}</TableCell>
                                                                <TableCell >{template.smsSender}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow>
                                                            <TableCell>
                                                                <TextField id='templateId'
                                                                    variant="outlined"
                                                                    required
                                                                    label="Template Id"

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField id='templateInfo'
                                                                    variant="outlined"
                                                                    required
                                                                    label="Template Info"

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField id='templateMessage'
                                                                    variant="outlined"
                                                                    required
                                                                    label="Template Message"

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField id='templateSmsSender'
                                                                    variant="outlined"
                                                                    required
                                                                    label="Sms Sender"

                                                                />
                                                            </TableCell>

                                                            <TableCell>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        const templateIdElement = document.getElementById('templateId')
                                                                        const templateInfoElement = document.getElementById('templateInfo')
                                                                        const templateMessageElement = document.getElementById('templateMessage')
                                                                        const templateSmsSenderElement = document.getElementById('templateSmsSender')

                                                                        const templateId = templateIdElement.value
                                                                        const templateInfo = templateInfoElement.value
                                                                        const templateMessage = templateMessageElement.value
                                                                        const smsSender = templateSmsSenderElement.value

                                                                        const newTemplate = { templateId, templateInfo, templateMessage, smsSender }
                                                                        const templates = this.state.smsOtpDetails.templates
                                                                        templates.push(newTemplate)

                                                                        templateIdElement.value = ''
                                                                        templateInfoElement.value = ''
                                                                        templateMessageElement.value = ''
                                                                        templateSmsSenderElement.value = ''


                                                                        this.setState({
                                                                            smsOtpDetails: { ...this.state.smsOtpDetails, templates: templates }

                                                                        })

                                                                    }}
                                                                >
                                                                    Add
                                                                </Button>
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button
                                                onClick={() => this.handleDialog("smsOtp")}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => this.handleDialogSubmit("smsOtp")}
                                                color="primary"
                                                disabled={(this.state.smsOtpDetails.limit <= 0 || this.state.smsOtpDetails.templates.length <= 0)}
                                            >
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    )}

                                </>
                            )
                        }
                        {!loading && selectedUser !== null && <Button
                            onClick={this.updateLimits}
                            color="primary"
                            variant="contained"
                            className="submit-btn"
                        >
                            {
                                updateLoading ?
                                    <CircularProgress style={{ color: '#fff' }} size={20} /> :
                                    'Submit'
                            }
                        </Button>}
                    </div>}
            </>
        );
    }

    generateDigilockerPassword() {
        var pass = '';
        var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
            'abcdefghijklmnopqrstuvwxyz0123456789@#$';

        for (let i = 1; i <= 15; i++) {
            var char = Math.floor(Math.random()
                * str.length + 1);

            pass += str.charAt(char)
        }

        return pass;
    }
}

export default UpdateUser;