import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Button, CircularProgress } from '@material-ui/core';
import "../styles/updateGC.scss";

class UpdateGC extends Component {
    state = {
        panBackend: null,
        updateLoading: false,
        selectedService: {label: 'PAN', value: 'pan'},
        panOptions: [{label: 'PAN Lite', value: 'pan-lite'}, {label: 'PAN Advance', value: 'pan-advance'}],
        selectedPAN: null,
    }

    componentDidMount() {
        this.fetchGCdata();
    }

    fetchGCdata = async () => {
        try {
            const { authkey } = this.props;
            let res = await axios.post(`${process.env.REACT_APP_GC_URL}/pan/fetch`, {}, { headers: { authkey } });
            if(res && res.data && res.data.message) {
                if (res.data.message === 'pan-lite') this.setState({ selectedPAN: {label: 'PAN Lite', value: 'pan-lite'} });
                else if (res.data.message === 'pan-advance') this.setState({ selectedPAN: {label: 'PAN Advance', value: 'pan-advance'} });
            } else alert('Something Went Wrong');
        } catch(e) {
            alert(e.message)
        }
    }

    updateGC = async () => {
        try {
            const { authkey } = this.props;
            const { selectedPAN } = this.state;
            let data = new FormData();
            data.append("pan_type", selectedPAN.value)
            let res = await axios.post(`${process.env.REACT_APP_GC_URL}/pan/update`, data, { headers: { authkey } });
            if(res && res.data && res.data.message) {
                if (res.data.message === 'ok') {
                    alert('Success');
                } else alert('Error');
            } else alert('Something Went Wrong');
        } catch(e) {
            alert(e.message)
        }
    }

    changePAN = selectedPAN => {
        this.setState({ selectedPAN });
    }

    render() {
        const { updateLoading, selectedService, panOptions, selectedPAN } = this.state;
        let backendOptions = [];
        let selectedBackend = null;
        if (selectedService.value === "pan") {
            backendOptions = panOptions;
            selectedBackend = selectedPAN;
        }
        return (
            <div className='update-gc-container'>
                <h4>Update Official DB Backend</h4>
                <label>GC Service</label>
                <Select
                    value={selectedService}
                    // onChange={this.handleChange}
                    options={[selectedService]}
                />
                <label>Service Backend</label>
                <Select
                    value={selectedBackend}
                    onChange={this.changePAN}
                    options={backendOptions}
                />
                <Button
                    onClick={this.updateGC}
                    disabled={!selectedBackend}
                    color="primary"
                    variant="contained"
                    className="submit-btn"
                >
                    {
                        updateLoading ?
                        <CircularProgress style={{ color: '#fff' }} size={20} /> :
                        'Submit'
                    }
                </Button>
            </div>
        );
    }
}

export default UpdateGC;